import { Box } from "@mui/material"
import ProductRatesTable from "../../../../components/ProductRatesTable"
import { filterAvailableActions } from "../../../../../../components/action/utils"
import { getProductRatesTableActions } from "../../../../components/product-rates-utils"
import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"

const processActions = (actions, refresh) => {
    return (actions || []).map((action) => {
        if (action?.reloadOnSuccess) {
            return {
                ...action,
                onExecute: (data, progressHandler, onSuccess, onError) => {
                    return action.onExecute(data, progressHandler, onSuccess, onError).then(() => refresh())
                }
            }
        }

        return action
    })
}

const ProductRates = ({entity, refresh}) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()

    if (!entity) {
        return null
    }

    if (!entity.rates) {
        entity.rates = []
    }

    const actionHandlers = {
        edit: (containerId, productId, data) => {
            // prepare data
            // process new and updated rates
            const productContainerData = {
                ...data,
                rates: data.rates.map(rate => ({
                    ...rate,
                    id: rate.id > 0 ? rate.id : null,
                    customer_types: rate.customer_type_ids.split(",").map((id) => ({ id: id })),
                }))
            }

            return apiClient.catalog.product.editContainerProduct(
                entity.id,
                entity.container.id,
                productContainerData
            )
        },
    }

    const actions = processActions(getProductRatesTableActions(entity, actionHandlers, t), refresh)

    return <Box pt={2}>
        <ProductRatesTable
            product={entity}
            actions={filterAvailableActions(actions, entity)}
            hideTitle={true}
        />
    </Box>
}

export default ProductRates 