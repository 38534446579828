import { uid } from "ckeditor5"
import { getTableActions } from "../ProductRatesPublicationsCollection/ProductRatesPublicationsCollection.actions"
import productContainerProductsEdit from "./components/actions/ProductContainerProductsEdit"
import ProductSelectorAction from "./components/actions/ProductSelectorAction"

export const getActions = (user, apiClient, entityId, t) => [
    ...getTableActions(t, apiClient, user)
        .filter((action) => action.scope === "row")
        .map((action) => {
            action.icon = null
            return action
        }),
    {
        id: "id",
        title: t("catalog.product_rates_publications.edit"),
        scope: "page",
        withDialog: {
            title: t("catalog.product_rates_publications.actions.edit.modal_title"),
            content: productContainerProductsEdit,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            progressHandler(-1, t("catalog.product_rates_publications.actions.edit.progress"))
            return apiClient.catalog.productRatesPublication.editProductRatePublication(result.id, result)
                .then((result) => {
                    onSuccess(
                        t("catalog.product_rates_publications.actions.edit.confirm", {
                            name: result.description,
                        })
                    )

                    return result
                })
                .catch((error) => {
                    onError(
                        t("catalog.product_rates_publications.actions.edit.error", {
                            name: result.description,
                        })
                    )
                })
        },
        condition: () => true
    },
    {
        title: t("catalog.product_containers.actions.products_add.button"),
        withDialog: {
            title: t("catalog.product_containers.actions.products_add.modal_title"),
            content: ProductSelectorAction,
            maxWidth: "lg",
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            const data = {
                add: result.map((selectedItem) => ({
                    id: selectedItem.id,
                })),
            }

            return apiClient.catalog.productRatesPublication.editProductRatePublicationProducts(entityId, user.corporate_account.enterprise.uid, "enterprise", data)
                .then((result) => {
                    onSuccess(
                        t("catalog.product_containers.actions.products_add.confirm")
                    )

                    return result
                })
                .catch((error) => {
                    onError(
                        t("catalog.product_containers.actions.products_add.error")
                    )
                })
        },
        condition: (entity) => entity.hasAction("edit"),
    }
]


