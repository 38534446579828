import { Field } from "formik"

const HiddenFormField = ({ field }) => {

    return (
        <Field
            type="hidden"
            name={field.id}
        />
    )
}

export default HiddenFormField
