import { useTranslation } from "react-i18next"
import constants from "../../../../components/entity/constants"
import { createBooleanFormField, createField, createFormField, createFormFieldGroup, createFormFieldset, } from "../../../../components/helpers/fieldHelper"
import EntityEditForm from "../../../actions/EntityEditForm"
import _get from "lodash/get"


const ProductRatesPublicationForm = ({ selection, onAction, mode = "add" }) => {
    const { t } = useTranslation("vbms")

    const entity = selection || {}

    const formContent = [
        createFormFieldset(null, [
            createFormField(
                createField(
                    "description",
                    t("catalog.product_rates_publications.fields.description"),
                    constants.STRING_TYPE
                ),
                true
            ),
            createFormField(
                createField(
                    "valid_from",
                    t("catalog.product_rates_publications.fields.valid_from"),
                    constants.DATE_TYPE
                ),
                true,
                new Date()
            ),
            createFormFieldGroup([
                createFormField(
                    createField(
                        "date_from",
                        t("catalog.product_rates_publications.fields.date_from"),
                        constants.DATE_TYPE
                    ),
                    true
                ),
                createFormField(
                    createField(
                        "date_to",
                        t("catalog.product_rates_publications.fields.date_to"),
                        constants.DATE_TYPE
                    ),
                    true
                ),
            ]),
            createFormFieldGroup(
                [
                    {
                        ...createBooleanFormField(
                            t,
                            "config.only_modified_products",
                            t("catalog.product_rates_publications.fields.only_modified_products"),
                            null,
                            false,
                            1
                        ),
                        condition: () => mode === "add"
                    },
                    createBooleanFormField(
                        t,
                        "config.hide_intermediaries",
                        t("catalog.product_rates_publications.fields.hide_intermediaries"),
                        null,
                        false,
                        1
                    ),
                ]
            ),
        ])
    ]

    return (
        <EntityEditForm
            selection={entity}
            formContent={formContent}
            onAction={onAction}
        />
    )
}

export default ProductRatesPublicationForm