import { createTabBarItem } from '../../../../components/helpers/fieldHelper';
import VenueSchedules from './components/tabs/VenueSchedules';
import VenueFacilityBookLines from './components/tabs/VenueFacilityBookLines';
import userPermissions from '../../../../lib/user-permissions';

export const getTabNavigationItems = (t, apiClient, user) => [
    
    createTabBarItem(
        t("venues.view.navbar.schedules"),
        VenueSchedules,
    )
/*    ,        
    createTabBarItem(
        t("venues.view.navbar.facility_book_lines"),
        ({ entity, data }) => {
            return <VenueFacilityBookLines
                entityId={entity.id}
            />
        },
        null,
        user.hasPermission(userPermissions.PERM_FACILITY_BOOK_LINES_VIEW)
    ),     */
];