import { useTranslation } from 'react-i18next';
import constants from '../../../../../components/entity/constants';
import { createField, createCallbackField } from '../../../../../components/helpers/fieldHelper';
import ArrayDataTable from '../../../../../components/Datatable/ArrayDataTable';
import _get from "lodash/get"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const SitePublications = (props) => {

    const { t } = useTranslation("vbms")

    const columns = [
        createField(
            "created",
            t("common.fields.date"),
            constants.DATETIME_TYPE,
        ),
        createField(
            "user.name",
            t("common.fields.comments.username"),
            constants.STRING_TYPE
        ),
        createField(
            "entity_action_log_type.name",
            t("sites.fields.action"),
            constants.STRING_TYPE
        ),
        createCallbackField(
            "data.success",
            t("sites.fields.result"),
            constants.STRING_TYPE,
            (value) => (value === undefined || value === null) ? "" : (value ? <CheckIcon /> : <CloseIcon />)
        )
    ]

    return (
        <ArrayDataTable
            data={props.data}
            columns={columns}
            highlight={(entity) => _get(entity, "data.success", "") === false ? "background.error.main" : _get(entity, "data.success", "") === true ? "background.success.main" : null}
        />
    )
}

export default SitePublications