import { Visibility } from "@mui/icons-material"
import { createTabBarItem } from "../../../../components/helpers/fieldHelper"
import ProductsTable from "../../../../components/Volcano/Catalog/ProductsTable"
import ProductContainerReferencesTable from "./components/tabs/ProductContainerReferencesTable"
import { Box } from "@mui/material"
import ProductRatesTable from "../../components/ProductRatesTable"
import { filterAvailableActions } from "../../../../components/action/utils"
import { getProductRatesTableActions } from "../../components/product-rates-utils"

const checkProductContainerReferenceType = (entity, containerType) => {
    if (!entity || !entity.product_container_references) {
        return false
    }

    return entity.product_container_references.some((container) => container.container_type === containerType)
}

export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("catalog.product_rates_publications.view.navbar.enterprise"),
        ({ entity }) => {
            const container = entity.product_container_references.find((container) => container.container_type === 'enterprise')
            container.product_rates_publication = {
                id: entity.id,
                description: entity.description,
            }

            return <ProductsTable
                filterParams={{ rates_publication_id: entity.id, container_id: container.id, container_type: "enterprise" }}
                actions={[
                    {
                        id: "view",
                        title: t("common.open"),
                        scope: "row",
                        icon: Visibility,
                        withDialog: {
                            title: (container) => t("catalog.product_rates_publications.actions.product_container_reference.view_products.modal_title", { name: container.name }),
                            content: (props) => {
                                const product = props.selection
                                
                                const actionHandlers = {
                                    edit: (containerId, productId, data) => apiClient.catalog.productRatesPublication.editContainerProduct(
                                        entity.id,
                                        containerId,
                                        "enterprise",
                                        productId,
                                        data
                                    ),
                                }
            
                                const actions = getProductRatesTableActions(container, actionHandlers, t)
            
                                return <Box pt={2}><ProductRatesTable product={product} actions={filterAvailableActions(actions, product)} /></Box>
                            },
                            maxWidth: "md",
                            closeIcon: true,
                        },
                        onExecute: (container, progressHandler, onSuccess, onError) => {},
                    },
                ]}
            />
        },
        null,
        (entity) => checkProductContainerReferenceType(entity, "enterprise")
    ),
    createTabBarItem(
        t("catalog.product_rates_publications.view.navbar.rate_groups"),
        ({ entity }) => <ProductContainerReferencesTable entity={entity} containerType="rate_group" />,
        null,
        (entity) => checkProductContainerReferenceType(entity, "rate_group")
    ),
    createTabBarItem(
        t("catalog.product_rates_publications.view.navbar.intermediaries"),
        ({ entity }) => <ProductContainerReferencesTable entity={entity} containerType="intermediary" />,
        null,
        (entity) => checkProductContainerReferenceType(entity, "intermediary")
    ),
]