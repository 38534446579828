import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _set from 'lodash/set'
import { validate } from '../components/form/utils'
import _uniqBy from 'lodash/uniqBy'
import { CUSTOMER_TYPE_IDS } from '../pages/Bookings/BookingsCollection/utils'

export const pickupDataFetcher = (apiClient, productId, date) => {
    return apiClient.route.getPickupData(productId, date)
        .then((route) => {
            // process lodgins, zones and pickup points

            // pickup points
            let pickupPoints = _uniqBy(
                route.pickup_configs
                    .map((pickupConfig) =>
                        pickupConfig.pickup_points.map((pickupPoint) => {
                            return {
                                ...pickupPoint,
                                pickup_config_id: pickupConfig.id,
                            }
                        })
                    )
                    .flat()
                    .sort((a, b) => a.name.localeCompare(b.name)),
                "id"
            )

            // zones
            const zones = _uniqBy(
                pickupPoints
                    .filter((pickupPoint) => pickupPoint.zone != null)
                    .map((pickupPoint) => pickupPoint.zone)
                    .sort((a, b) => a.name.localeCompare(b.name)),
                "id"
            )

            // lodgins
            const lodgins = _uniqBy(
                pickupPoints
                    .filter(
                        (pickupPoint) =>
                            Array.isArray(pickupPoint.lodgins) &&
                            pickupPoint.lodgins.length > 0
                    )
                    .map((pickupPoint) =>
                        pickupPoint.lodgins.map((lodgin) => {
                            return {
                                id: lodgin.id,
                                name: lodgin.name,
                                pickup_point_id: pickupPoint.id,
                                order: lodgin.order,
                            }
                        })
                    )
                    .flat()
                    .sort((a, b) => a.name.localeCompare(b.name) || a.order - b.order),
                "id"
            )

            pickupPoints = pickupPoints.map((pickupPoint) => {
                return {
                    id: pickupPoint.id,
                    pickup_config_id: pickupPoint.pickup_config_id,
                    name: pickupPoint.name,
                    coordinates: pickupPoint.coordinates,
                    zone_id:
                        pickupPoint.zone != null
                            ? pickupPoint.zone.id
                            : null,
                    order: pickupPoint.order,
                    pickup_time: pickupPoint.pickup_time,
                }
            })

            return {
                lodgins: lodgins,
                zones: zones,
                pickup_points: pickupPoints,
            }
        })
        .catch((error) => {
            return {
                lodgins: [],
                zones: [],
                pickup_points: [],
            }
        })
}

export const validateParticipants = (rates, firstNodeName, cardNodeName, validateFormFields, t) => {
    const errors = {}
    const participantValues = []

    const setIdentifierError = (p) => {
        if (p.id != null) {
            _set(errors,
                firstNodeName + `[${p.index}].participants[${p.participantIndex}].id`,
                p.id
            )
        } else if (p.row_id != null) {
            _set(errors,
                firstNodeName + `[${p.index}].participants[${p.participantIndex}].row_id`,
                p.row_id
            )
        }
    }

    rates.forEach((rate, index) => {
        const participants = _get(rate, 'participants', [])
        participantValues.push(
            ...participants
                .filter((participant) => !_get(participant, "deleted", false))
                .map(
                    (participant, participantIndex) => {
                        const id_card = (participant?.id_card ?? participant?.nif ?? '').toLowerCase();

                        return {
                            index: index,
                            participantIndex: participantIndex,
                            id: _has(participant, 'id') ? participant.id : null,
                            row_id: _has(participant, 'row_id') ? participant.row_id : null,
                            value_name: ((participant.first_name + participant.last_name).replace(/[0-9\s"'`´]/g, '') + id_card).toLowerCase(),
                            value_first_name: participant.first_name,
                            value_last_name: participant.last_name,
                            value_id_card: id_card,
                            required_id_card: _has(rate, 'customer_types') ?
                                !rate.customer_types.includes(CUSTOMER_TYPE_IDS.CHILDREN) : // only check id card for non children participants
                                true
                        }
                    }))
    })

    participantValues
        .filter((participant, participantIndex, participants) => {
            const findIndexValue = participants.findIndex(p => p.value_name.length > 0 && p.value_name === participant.value_name)

            return findIndexValue !== participantIndex && findIndexValue > -1
        })
        .forEach((p) => {
            setIdentifierError(p)
            _set(errors,
                firstNodeName + `[${p.index}].participants[${p.participantIndex}].first_name`,
                t("booking_widget.steps.client.validation.participant_repeated"))
        })

    participantValues
        .filter((participant, participantIndex, participants) => {
            const findIndexValue = participants.findIndex(p => p.value_id_card.trim().length > 0 && p.value_id_card === participant.value_id_card)

            return findIndexValue !== participantIndex && findIndexValue > -1
        })
        .forEach((p) => {
            setIdentifierError(p)
            _set(errors,
                firstNodeName + `[${p.index}].participants[${p.participantIndex}].` + cardNodeName,
                t("booking_widget.steps.client.validation.participant_id_card_repeated")
            )
        })

    if (validateFormFields) {
        participantValues
            .forEach((p) => {
                let error = validate({ type: 'string', required: true }, t, p.value_first_name)

                if (error) {
                    setIdentifierError(p)
                    _set(errors,
                        firstNodeName + `[${p.index}].participants[${p.participantIndex}].first_name`,
                        error
                    )
                }

                error = validate({ type: 'string', required: true }, t, p.value_last_name)

                if (error) {
                    setIdentifierError(p)
                    _set(errors,
                        firstNodeName + `[${p.index}].participants[${p.participantIndex}].last_name`,
                        error
                    )
                }

                error = participantValues.required_id_card && validate({ type: 'nif', required: true }, t, p.value_id_card)

                if (error) {
                    setIdentifierError(p)
                    _set(errors,
                        firstNodeName + `[${p.index}].participants[${p.participantIndex}].` + cardNodeName,
                        error
                    )
                }
            })
    }

    return errors
}