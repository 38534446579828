import { ContentCopyOutlined, DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material"
import RateGroupForm from "../actions/RateGroupForm"
import userPermissions from "../../../../lib/user-permissions"

/**
 * Generic handler for rate group related actions. 
 */
const onExecuteHandler = (t, result, onSuccess, onError, saveFunction, i18nPrefix) => {
    // convert commission percentage to decimal
    result.config.default_commission = result.config.default_commission / 100

    return saveFunction(result)
        .then((result) => { 
            onSuccess(t(i18nPrefix + ".confirm", {name: result.name}))
            return result
        })
        .catch((error) => onError(t(i18nPrefix + ".error", {name: result.name})))
}

export const getTableActions = (t, apiClient, user) => [
    {
        title: t("catalog.rate_groups.add"),
        scope: "page",
        withDialog: {
            title: t("catalog.rate_groups.actions.add.modal_title"),
            content: RateGroupForm,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return onExecuteHandler(
                t,
                result, 
                onSuccess, 
                onError, 
                apiClient.catalog.rateGroup.addRateGroup, 
                "catalog.rate_groups.actions.add"
            )
        },
        condition: () => user.hasPermission(userPermissions.PERM_CATALOG_ADMINISTER)
    },
    {
        id: "edit",
        title: t("common.edit"),
        scope: "row",
        icon: EditOutlined,
        withDialog: {
            title: t("catalog.rate_groups.actions.edit.modal_title"),
            content: RateGroupForm,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            debugger
            return onExecuteHandler(
                t,
                result, 
                onSuccess, 
                onError, 
                (data) => apiClient.catalog.rateGroup.editRateGroup(result.id, data), 
                "catalog.rate_groups.actions.edit"
            )
        },
        condition: (entity) => entity.hasAction("edit")
    },
    {
        id: "copy",
        title: t("common.copy"),
        scope: "row",
        icon: ContentCopyOutlined,
        withDialog: {
            title: t("catalog.rate_groups.actions.copy.modal_title"),
            content: ({ selection, onAction }) => <RateGroupForm
                selection={{
                    ...selection,
                    name: t("catalog.rate_groups.actions.copy.entity_name", {
                        name: selection.name
                    })
                }}
                onAction={onAction}
            />,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return onExecuteHandler(
                t, 
                result, 
                onSuccess, 
                onError, 
                (data) => {
                    const entityId = data.id
                    delete data.id                    
                    return apiClient.catalog.rateGroup.copyRateGroup(entityId, data)
                },
                "catalog.rate_groups.actions.add"
            )
        },
        condition: (entity) => entity.hasAction("copy")
    },
    {
        id: "delete",
        title: t("common.delete"),
        scope: "row",
        icon: DeleteOutlineOutlined,
        confirmDialog: {
            title: t("catalog.rate_groups.actions.delete.modal_title"),
            content: (selection) =>
                t("catalog.rate_groups.actions.delete.modal_content", {
                    name: selection.name,
                }),
        },
        reloadOnSuccess: true,
        onExecute: (entity, progressHandler, onSuccess, onError) => {
            return apiClient.catalog.rateGroup.deleteRateGroup(entity.id)
                .then(() => {
                    onSuccess(
                        t("catalog.rate_groups.actions.delete.confirm", {
                            name: entity.name,
                        })
                    )

                    return entity
                })
                .catch((error) => {
                    onError(
                        t("catalog.rate_groups.actions.delete.error", {
                            name: entity.name,
                        })
                    )

                    throw error
                })
        },
        condition: (entity) => entity.hasAction("delete")
    },
]