import { useParams } from "react-router-dom"
import { createCallbackField } from "../../../../components/helpers/fieldHelper"
import { getHeaderContent } from "./ProductRatesPublicationPage.header"
import { getTabNavigationItems } from "./ProductRatesPublicationPage.tabs"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import constants from "../../../../components/entity/constants"
import AsyncEntityPage from "../../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { getActions } from "./ProductRatesPublicationPage.actions"
import { getShareActions } from "./ProductRatesPublicationPage.shareActions"

const ProductRatesPublicationPage = (props) => {
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()

    return (
        <AsyncEntityPage
            fetcher={() => apiClient.catalog.productRatesPublication.getProductRatesPublication(entityId)}
            title={createCallbackField(
                "description",
                t("catalog.product_rates_publications.view.title"),
                constants.STRING_TYPE,
                (value) => {
                    return t("catalog.product_rates_publications.view.title", { name: value })
                }
            )}
            headerContent={getHeaderContent(t)}
            actions={getActions(volcanoAuth.user, apiClient, entityId, t)}
            shareActions={getShareActions(volcanoAuth.user, apiClient, entityId, t)}
            tabNavigationItems={getTabNavigationItems(volcanoAuth.user, apiClient, t)}
        />
    )
}

export default ProductRatesPublicationPage