import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react"
import { useVolcanoAuth } from "./VolcanoAuthContext"
import { useVolcanoApiClient } from "./VolcanoApiClientProvider"
import _get from "lodash/get"
import _has from "lodash/has"
import Loading from "../components/Display/Loading"
import { useTranslation } from 'react-i18next'

const stub = () => {
    throw new Error('You forgot to wrap your component in <CacheProvider></CacheProvider>.')
}

const initialState = {
    store: {},
    get: stub,
    set: stub,
    has: stub,
    clear: stub,
}

const Context = createContext(initialState)

const reducer = (state, action) => {
    switch (action.type) {
        case "INITIALISE":
            return {
                ...state,
                store: action.value,
            }
        case "SET":
            return {
                ...state,
                store: {
                    ...state.store,
                    [action.key]: action.value,
                },
            }
        case "CLEAR":
            const { [action.key]: _, ...rest } = state.store
            return {
                ...state,
                store: rest,
            }
        case "CLEAR_ALL":
            return {
                ...state,
                store: {},
            }
        default:
            return state
    }
}

const CacheProvider = ({ children }) => {
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const { t } = useTranslation("vbms")
    const [state, dispatch] = useReducer(reducer, initialState)
    const [ready, setReady] = useState(false)
    const [enterpriseId, setEnterpriseId] = useState(volcanoAuth.user ? volcanoAuth.user.corporate_account.enterprise.id : null)

    const fetchers = useMemo(() => ({
        customer_type_groups: () => apiClient.catalog.customerTypeGroup.getCustomerTypeGroups({ active: true }).then((result) => result.getItems()),
        subcategories: () => apiClient.content.subcategory
            .getSubcategories(null, "compact")
            .then((result) => result.getItems()),
        salesmen: () => apiClient.user.getApplicationUsers({ limit: 100 }).then((result) => {
            return result.getItems()
                .filter((user) => _has(user, "salesman"))
                .map((user) => ({
                    id: user.salesman.id,
                    name: user.name,
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
        }),
        contact_schema: () => apiClient.schema.getContactsSchema(),
        crm_intermediaries_schema: () => apiClient.schema.getCrmIntermediariesSchema(),
        offices: () => apiClient.intermediary
            .getIntermediaryOffices(
                null,
                { limit: 100 }
            )
            .then((result) =>
                result
                    .getItems()
                    .map((office) => ({
                        id: office.id,
                        collaborator_office_id: office.collaborator_office_id,
                        name: office.name,
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name))
            ),
        crm_salesmen: () => apiClient.crm.salesman
            .getSalesmen({
                limit: 100,
                view_mode: "summary"
            })
            .then((result) => result
                .getItems()
                .map((salesman) => ({
                    id: salesman.id,
                    name: salesman.name,
                }))
            ),
        sites: () => apiClient.content.getSites().then((result) => result.getItems()),
        tag_groups: () => apiClient.content.tag.getTagGroups().then((result) => result.getItems()),
        tags: () => apiClient.content.tag.getTags().then((result) => result.getItems()),
        notification_schema: () => apiClient.schema.getNotificationsSchema(),
        languages: () => apiClient.catalog.language.getLanguages().then((result) => result.getItems()),
        transports: () => apiClient.catalog.transport.getTransports().then((result) => result.getItems()),
        remote_product_providers: () => apiClient.catalog.remoteProductProvider.getRemoteProductProviders().then((result) => result.getItems())
    }), [apiClient])

    const has = useCallback((key) => {
        return _has(state.store, key)
    }, [state.store])

    const set = useCallback((key, value) => {
        dispatch({
            type: "SET",
            key,
            value,
        })
    }, [])

    const get = useCallback((key, defaultValue, fetcher) => {
        // check if the cache is ready
        if (_has(state.store, key)) {
            return Promise.resolve(_get(state.store, key, defaultValue || null))
        }

        // if there is a fetcher for the key, fetch the content
        if (_has(fetchers, key)) {
            return fetchers[key]().then((result) => {
                set(key, result)
                return result
            })
        }

        // if there is a fetcher function, call it
        if (fetcher) {
            return fetcher().then((result) => {
                set(key, result)
                return result
            })
        }

        return Promise.resolve(null)
    }, [state.store, fetchers, set])

    const clear = useCallback((key) => {
        dispatch({
            type: "CLEAR",
            key,
        })
    }, [])

    const clearAll = useCallback((key) => {
        dispatch({ type: "CLEAR_ALL" })
    }, [])


    useEffect(() => {
        setReady(false)
        if (localStorage.cacheStore) {
            if (localStorage.cacheStore === '{}') {
                clearAll()
                localStorage.removeItem('cacheStore')
            } else {
                dispatch({
                    type: "INITIALISE",
                    value: JSON.parse(localStorage.cacheStore),
                })

                setReady(true)
                return
            }
        }

        if (volcanoAuth.user) {
            if (!volcanoAuth.user.isIntermediary()) {
                // only prefetch subcategories and salesmen if the user is not an intermediary
                setReady(true)
                return
            }

            const initialStoreKeys = ["subcategories", "salesmen"]

            Promise.all(
                initialStoreKeys.map((key) =>
                    fetchers[key]()
                        .then((result) => {
                            set(key, result)
                            return { key, result }
                        })
                        .catch((error) => {
                            setReady(true)
                        })
                )
            )

            return
        } else {
            clearAll()
        }

        setReady(true)
    }, [apiClient, volcanoAuth.user, fetchers, clearAll, set])

    useEffect(() => {
        if (volcanoAuth.user && enterpriseId !== volcanoAuth.user.corporate_account.enterprise.id) {
            setEnterpriseId(volcanoAuth.user.corporate_account.enterprise.id)
            clearAll()
        }
    }, [volcanoAuth.user, enterpriseId, clearAll])

    useEffect(() => {
        const keys = ["subcategories", "salesmen"]

        if (!ready && keys.every((key) => has(key))) {
            setReady(true)
        }
    }, [ready, has, setReady])

    useEffect(() => {
        localStorage.setItem("cacheStore", JSON.stringify(state.store))
    }, [state.store])

    const contextValue = useMemo(() => {
        return {
            ...state,
            get,
            set,
            has,
            clear,
        }
    }, [
        state,
        get,
        set,
        has,
        clear,
    ])

    return (
        <>
            <Context.Provider value={contextValue}>
                {ready ? children : <Loading open={!ready} title={t("app.loading")} />}
            </Context.Provider>
        </>
    )
}

const useCache = () => useContext(Context)

export { CacheProvider, useCache }