import { Box } from "@mui/material";
import constants from "../../entity/constants";
import { createBookingStateMapping, createCallbackField, createField, createLinkField } from "../../helpers/fieldHelper";
import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import CollectionDataTable from "../../Datatable/CollectionDataTable";
import userPermissions from "../../../lib/user-permissions";



const getTableColumns = (t, user) => ([
    createLinkField(
        createField(
            "locator",
            t("bookings.fields.locator"),
            constants.TEXT_TYPE
        ),
        {
            route: "bookings",
            field: "id",
        }
    ),
    createCallbackField(
        "product",
        t("bookings.fields.product"),
        constants.STRING_TYPE,
        (value) => <Box>{value.experience.name}<br />{value.name}</Box>
    ),
    createCallbackField(
        "order.customer",
        t("bookings.fields.order.customer.title"),
        constants.STRING_TYPE,
        (value) => <Box>{value.first_name + " " + value.last_name}<br />{value.email}</Box>
    ),
    createField(
        "order.created",
        t("bookings.fields.order.created"),
        constants.DATETIME_TYPE
    ),
    createField(
        "booking_date",
        t("bookings.fields.booking_date"),
        constants.DATETIME_TYPE
    ),
    createField(
        "validation_date",
        t("bookings.fields.validation_date"),
        constants.DATETIME_TYPE
    ),
    createField(
        "state",
        t("bookings.fields.state"),
        constants.STRING_TYPE,
        createBookingStateMapping(t)
    ),
    createField(
        "order.language",
        t("bookings.fields.order.language"),
        constants.STRING_TYPE,
        {
            deu: t("common.languages.deu"),
            eng: t("common.languages.eng"),
            fra: t("common.languages.fra"),
            ita: t("common.languages.ita"),
            nld: t("common.languages.nld"),
            pol: t("common.languages.pol"),
            rus: t("common.languages.rus"),
            spa: t("common.languages.spa"),
        }
    ),
    createCallbackField(
        "product_rates",
        t("bookings.fields.pax"),
        constants.NUMBER_TYPE,
        (value) => (value || []).reduce((acc, rate) => acc + rate.qty, 0)
    ),
    createField(
        "amount.total_amount_pvp",
        t("bookings.fields.total_amount_pvp"),
        constants.CURRENCY_TYPE
    ),
    createField(
        "amount.total_amount",
        t("bookings.fields.total_amount_net"),
        constants.CURRENCY_TYPE,
        null,
        () => user.hasPermission(userPermissions.PERM_BOOKINGS_VIEW_NET_PRICE)
    )
])


const IntermediarySalesTable = ({ defaultParams }) => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()

    return (
        <CollectionDataTable
            ignoreSearchParams={true}
            fetcher={(params) => {
                params = {
                    ...defaultParams
                    , ...params
                }
                
                return apiClient.booking.getBookings(params)
            }}
            columns={getTableColumns(t, volcanoAuth.user)}
        />
    )
}

export default IntermediarySalesTable