import { useTranslation } from "react-i18next";
import { getRecipientsPrepareActions } from "./NotificationRecipients.functions";
import { createBooleanFormField, createCallbackField, createField, createIconField, createLanguagesMapping, createSelectFormField, languagesCode2 } from "../../../../../../components/helpers/fieldHelper";
import constants from "../../../../../../components/entity/constants";
import { Box } from "@mui/material";
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider";
import { useNavigate } from "react-router-dom";
import { useVolcanoAuth } from "../../../../../../context/VolcanoAuthContext";
import CollectionDataTable from "../../../../../../components/Datatable/CollectionDataTable";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from "react";

const getTableFilter = (t) => ([
    createField(
        "name",
        t("notifications.filter.name"),
        constants.TEXT_TYPE
    ),
    createField(
        "email",
        t("notifications.fields.email"),
        constants.TEXT_TYPE
    ),
    createField(
        "phone",
        t("notifications.fields.phone"),
        constants.TEXT_TYPE
    ),
    createBooleanFormField(
        t,
        "no_send",
        t("notifications.fields.send")
    ),
    createSelectFormField(
        "languages",
        t("notification_templates.fields.recipients.language"),
        Object.entries(createLanguagesMapping(t, languagesCode2(), true))
            .map(([key, value]) => (
                {
                    value: key,
                    label: value
                }
            )),
    ),
])


const getTableColumns = (t) => ([
    createField(
        "name",
        t("notifications.filter.name"),
        constants.TEXT_TYPE
    ),
    createField(
        "email",
        t("notifications.fields.email"),
        constants.STRING_TYPE
    ),
    createField(
        "phone",
        t("notifications.fields.phone"),
        constants.STRING_TYPE
    ),
    createField(
        "language",
        t("notifications.fields.language"),
        constants.STRING_TYPE,
        createLanguagesMapping(t)
    ),
    createCallbackField(
        "send",
        t("notifications.fields.send"),
        constants.STRING_TYPE,
        (value) => <Box>{(value) ? t("common.yes") : t("common.no")}</Box>,
    ),
    createIconField(
        "id",
        VisibilityIcon,
        t("common.actions.preview.button"),
        {
        },
        true
    ),
])

const NotificationRecipientsPrepareGuides = ({ defaultParams, details }) => {
    const { t } = useTranslation("vbms");
    const { apiClient } = useVolcanoApiClient()
    const navigate = useNavigate()
    const volcanoAuth = useVolcanoAuth()

    const entityId = useState(defaultParams.notification_id)
    const actions = getRecipientsPrepareActions(entityId, details, 'guide', t, apiClient, volcanoAuth.user, navigate)
 
    const filterConfig = getTableFilter(t)

    return (

        <CollectionDataTable
            ignoreSearchParams={true}
            fetcher={(query) => {

                const params = {
                    ...defaultParams,
                    ...query
                }

                return apiClient.notifications.getRecipients(defaultParams.notification_id, params)
            }}
            selectionMode="multiple"
            columns={getTableColumns(t)}
            actions={actions}
            filterConfig={filterConfig}
            highlight= {(entity) => entity.send ? null : "background.warning.main"}
        />
    )

}

export default NotificationRecipientsPrepareGuides