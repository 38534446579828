import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionDataTable from "../../Datatable/CollectionDataTable"
import { createCommonStateMapping, createCommonVisibilityMapping, createField, createFormField, createOptionsMapping, createSelectFormField } from "../../helpers/fieldHelper"
import constants from "../../entity/constants"

const ProductsTable = ({ filterParams, actions }) => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")

    const table = {
        filterConfig: [
            createField(
                "name",
                t("catalog.products.fields.name"),
                constants.STRING_TYPE
            ),
            createField(
                "sku",
                t("catalog.products.fields.sku"),
                constants.STRING_TYPE
            ),
            createField(
                "experience_name",
                t("catalog.products.fields.experience"),
                constants.STRING_TYPE
            ),
            createFormField(
                createSelectFormField(
                    "state",
                    t("catalog.products.fields.state"),
                    createOptionsMapping(t, createCommonStateMapping(t), true)
                ),
                false,
                "all"
            ),
            createFormField(
                createSelectFormField(
                    "visibility",
                    t("common.visibility.title"),
                    createOptionsMapping(t, createCommonVisibilityMapping(t), true)
                ),
                false,
                "all"
            ),
        ],
        columns: [
            createField(
                "name",
                t("catalog.products.fields.name"),
                constants.STRING_TYPE
            ),
            createField(
                "sku",
                t("catalog.products.fields.sku"),
                constants.STRING_TYPE
            ),
            createField(
                "experience.name",
                t("catalog.products.fields.experience"),
                constants.STRING_TYPE
            ),
            createField(
                "active",
                t("catalog.products.fields.active"),
                constants.BOOLEAN_TYPE
            ),
            createField(
                "visibility",
                t("common.visibility.title"),
                constants.STRING_TYPE,
                createCommonVisibilityMapping(t)
            ),
        ],
        highlight: (product) => product.visibility === "private" ? "background.warning.main" : "",
        fetcher: (params) => {
            const queryParams = {
                ...params,
                ...filterParams || {}
            }
            return apiClient.catalog.product.getProducts(queryParams)
        },
        actions: actions,
    }

    return (
        <CollectionDataTable {...table} />
    )
}

export default ProductsTable