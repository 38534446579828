import { useTranslation } from "react-i18next"
import CollectionDataTable from "../../../../../components/Datatable/CollectionDataTable"
import constants from "../../../../../components/entity/constants"
import { createField } from "../../../../../components/helpers/fieldHelper"
import { useVolcanoApiClient } from "../../../../../context/VolcanoApiClientProvider"
import { Download, Visibility } from "@mui/icons-material"
import { downloadFile, getSuggestedFilename } from "../../../../../lib/utils"
import ProductContainerReferenceProductRates from "../../../../Catalog/ProductRatesPublications/ProductRatesPublicationPage/components/actions/ProductContainerReferenceProductRates"
import { Box } from "@mui/material"


const IntermediaryRatesCalendar = ({ entity }) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()

    if (!entity) {
        return null;
    }

    const columns = [
        createField(
            "description",
            t("catalog.product_rates_publications.fields.description"),
            constants.STRING_TYPE
        ),
        createField(
            "date_from",
            t("catalog.product_rates_publications.fields.date_from"),
            constants.DATE_TYPE
        ),
        createField(
            "date_to",
            t("catalog.product_rates_publications.fields.date_to"),
            constants.DATE_TYPE
        )
    ]

    const fetcher = () => apiClient.catalog.productRatesPublication.getProductRatesPublications({ crm_intermediary_id: entity.id })

    const container = {
        id: entity.id,
        name: entity.name,
        container_type: "intermediary",
    }

    const actions = [
        {
            id: "view",
            title: t("common.open"),
            scope: "row",
            icon: Visibility,
            withDialog: {
                title: (container) => t("catalog.product_rates_publications.actions.product_container_reference.view_products.modal_title", { name: entity.name }),
                content: (props) => {
                    const entity = apiClient.getEntityInstance(null, {...container, product_rates_publication_id: props.selection.id})
                    return <Box pt={2}><ProductContainerReferenceProductRates selection={entity} /></Box>
                },
                maxWidth: "lg",
                closeIcon: true,
            },
            onExecute: (container, progressHandler, onSuccess, onError) => {},
        },
        {
            id: "download_pdf",
            title: t("common.download"),
            scope: "row",
            icon: Download,
            onExecute: (productRatesPublication, progressHandler, onSuccess, onError) => {
                const fileName = getSuggestedFilename(productRatesPublication.description + " - " + entity.name) + ".pdf"
                apiClient.catalog.productRatesPublication.getProductRatesPublicationPdf(
                    productRatesPublication.id,
                    entity.id,
                    "intermediary"
                )
                    .then(pdf => downloadFile(pdf, fileName))
            },
        }
    ]

    return <CollectionDataTable
        fetcher={fetcher}
        columns={columns}
        actions={actions}
    />
}

export default IntermediaryRatesCalendar