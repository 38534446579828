import { Card, CardContent, CardHeader, Chip, Stack } from "@mui/material"
import _isArray from "lodash/isArray"

const DataWrapper = ({ title, subtitle, subtitleVariant, onDelete, children }) => {
  subtitleVariant = subtitleVariant || "chip"


  if (subtitle && subtitleVariant === "chip") {

    const subtitleContent = _isArray(subtitle) ? subtitle : [subtitle]
  }

  return (
    <Card variant="outlined" sx={{
      height: "100%",
      pb: 1,
      "& .MuiCard-root": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
      "& .MuiCardActionArea-root": {
        height: "100%",
      },
      "& .MuiCardHeader-root": {
        pb: 0.5,
      },
      "& .MuiCardHeader-title": {
        fontWeight: 700,
        color: "common.text",
      },
      "& .MuiCardHeader-subheader": {
        mt: 1,
      },
      "& .MuiCardContent-root": {
        pt: 0.5,
        pb: 0
      },
    }}>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: "body1" }}
        subheader={subtitle && subtitleVariant === "text" && subtitle}
      />
      <CardContent>
        {subtitle && subtitleVariant === "chip" && _isArray(subtitle) && (
          <Stack direction="row" spacing={1}>
            {
              subtitle.map((subtitleItem, index) => (
                <Chip
                  key={index}
                  variant="outlined"
                  label={subtitleItem}
                  onDelete={onDelete}
                  color="primary"
                />

              ))
            }
          </Stack>
        )}
        {subtitle && subtitleVariant === "chip" && !_isArray(subtitle) && (

          < Chip
            variant="outlined"
            label={subtitle}
            onDelete={onDelete}
            color="primary"
          />
        )}
        {children}
      </CardContent>
    </Card >
  )
}

export default DataWrapper
