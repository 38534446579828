import { createField, createCallbackField, createArrayField } from '../../../../components/helpers/fieldHelper'
import DisfrutareLink from "../../../../components/Volcano/Disfrutare/DisfrutareLink"
import availableRoutes from '../../../../routes/availableRoutes'
import constants from "../../../../components/entity/constants"

export const getTableColumns = (t, user) => ([
    createField(
        "id",
        "ID",
        constants.STRING_TYPE
    ),
    createCallbackField(
        "name",
        t("common.name"),
        constants.STRING_TYPE,
        (value, entity) => <DisfrutareLink href={availableRoutes.disfrutare_subcategory_view.path.replace(":entityId", entity.id)}>{entity.name}</DisfrutareLink>
    ),
    createArrayField(
        createField(
            "sites",
            t("common.websites"),
            constants.ARRAY_TYPE
        ),
        "name"
    ),
    createField(
        "visible",
        t("common.visible"),
        constants.BOOLEAN_TYPE,
    )
])
