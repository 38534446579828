import userPermissions from '../../../../lib/user-permissions'
import availableRoutes from '../../../../routes/availableRoutes'
import catalogRoutes from "../../config/routes";

export const getActions = (apiClient, t, itemProps, navigate, user, entityId = null) => {
    const { reloadOnSuccess } = { ...itemProps }
    return [
        {
            id: "edit",
            title: t("catalog.products.actions.edit.button"),
            onExecute: (product) => {
                return Promise.resolve(product)
                    .then((product) => {
                        navigate(availableRoutes.catalog_products_edit.path.replace(":entityId", entityId ?? product.id))
                    })
            },
            condition: (product) => product.hasAction("edit")
        },
        {
            id: "translate",
            title: t("catalog.products.actions.translate.button"),
            onExecute: (product) => {
                const baseUrl = user.settings.disfrutare.base_url
                const loginUrl = user.settings.disfrutare.login_url
                const url = (baseUrl + loginUrl).replace("[TOKEN]", localStorage.getItem("jwt"))

                window.open(url.replace("[URL]", catalogRoutes.disfrutare_product_translate.path.replace(":entityId", product.id)))
            },
            condition: (product) => product.hasAction("edit")
        },
        {
            id: "availability",
            title: t("catalog.products.actions.availability.button"),
            onExecute: (product) => {
                const baseUrl = user.settings.disfrutare.base_url
                const loginUrl = user.settings.disfrutare.login_url
                const url = (baseUrl + loginUrl).replace("[TOKEN]", localStorage.getItem("jwt"))

                window.open(url.replace("[URL]", catalogRoutes.disfrutare_availability_products.path.replace(":entityId", ":" + product.id)))
            },
            condition: (product) => product.hasAction("edit_availability")
        },
    ]
}
