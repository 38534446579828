import AsyncEntityForm from "../../../../../../components/form/AsyncEntityForm"
import ValueSelectorDialog from '../../../../../../components/ValueSelectorDialog/ValueSelectorDialog'
import { useTranslation } from "react-i18next"
import { createFormFieldset, createEditableListFormField, createField } from '../../../../../../components/helpers/fieldHelper'
import { useVolcanoApiClient } from '../../../../../../context/VolcanoApiClientProvider'
import { getProductsFilter } from '../../../../../../lib/collection-filters'
import { useVolcanoAuth } from '../../../../../../context/VolcanoAuthContext'
import constants from "../../../../../../components/entity/constants"

const ExperienceProductsForm = ({ selection, onAction }) => {
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()
    const { t } = useTranslation("vbms")
    const productsFilterField = getProductsFilter(apiClient, t, user, true, {
        state: "active",
        not_ids: selection.products.map((item) => item.id)
    })
    const experienceSortType = selection.sort_type
    const fetcher = () => Promise.resolve(selection)
    const handleOnConfirm = (value) => {
        onAction({
            experience: {
                id: selection.id,
                name: selection.name
            },
            products: value.products.map((item) => {
                return {
                    id: parseInt(item.id),
                    name: item.label,
                }
            })
        })
    }

    const formContent = [
        createFormFieldset("", [
            createEditableListFormField(
                "products",
                null,
                "name",
                t("catalog.product_containers.actions.products_add.modal_title"),
                ({ selected, onConfirm, onCancel }) => <ValueSelectorDialog
                    table={productsFilterField.table}
                    selected={selected}
                    valueFormatter={productsFilterField.valueFormatter}
                    multipleSelection={true}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                />,
                experienceSortType === 'custom',
                (value) => {
                    return (value.is_main_experience == true) ? false : true
                },
                null,
                null,
                [
                    createField("is_main_experience", "", constants.HIDDEN_TYPE),
                ]
            )
        ])
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={handleOnConfirm}
            formContent={formContent}
        />
    )
}

export default ExperienceProductsForm
