import { createExportField, createCommonStateMapping } from "../../../../components/helpers/fieldHelper";

export const getTableExport = (apiClient, t) => {
    return (filter, progressHandler) => {
        apiClient.catalog.experience.exportExperiences(
            [
                createExportField(
                    "id",
                    "id",
                ),
                createExportField(
                    "name",
                    t("catalog.experiences.fields.name")
                ),
                createExportField(
                    "sites",
                    t("common.websites"),
                    null,
                    (entity) => entity.sites.map((sites) => sites.name).join(", ")
                ),
                createExportField(
                    "main_subcategory.name",
                    t("catalog.experiences.fields.main_subcategory")
                ),
                createExportField(
                    "state",
                    t("common.visible"),
                    createCommonStateMapping(t)
                )
            ],
            filter,
            progressHandler
        )
    }
} 