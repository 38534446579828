import constants from "../../../../components/entity/constants"
import { createAsyncSelectFormField, createBooleanFormField, createField, createFormField } from "../../../../components/helpers/fieldHelper"
import { buildOptions, sitesFetcher } from "../../../../lib/form-options-fetchers"

export const getTableFilter = (t, apiClient, user, cache) => ([
    createField(
        "ids",
        "ID",
        constants.STRING_TYPE
    ),
    createFormField(
        createField(
            "name",
            t("common.name"),
            constants.STRING_TYPE
        ),
        false
    ),
    createBooleanFormField(
        t,
        "visible",
        t("common.visible"),
        null,
        true,
        1
    ),
    createFormField(
        createAsyncSelectFormField(
            "site_id",
            t("common.websites"),
            (params) => sitesFetcher(cache, apiClient, params).then((items) => {
                const sites = items.filter((site) => site.enterprise.id === user.corporate_account.enterprise.id)
                return buildOptions(sites, t)
            }),
            true,
            null,
            null,
            false,
            false
        ),
        false,
        []
    ),
])
