import { getTableColumns } from './SubcategoriesCollectionPage.columns'
import { getTableFilter } from "./SubcategoriesCollectionPage.filter"
import { getTableExport } from "./SubcategoriesCollectionPage.export"
import { useVolcanoApiClient } from '../../../../context/VolcanoApiClientProvider'
import { useTranslation } from 'react-i18next'
import { useVolcanoAuth } from '../../../../context/VolcanoAuthContext'
import { useCache } from '../../../../context/cache.context'
import CollectionPage from '../../../../layout/templates/CollectionPage/CollectionPage'

const SubcategoriesCollectionPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()

    const filterConfig = getTableFilter(t, apiClient, volcanoAuth.user, cache)

    const table = {
        columns: getTableColumns(t, volcanoAuth.user),
        actions: [],
        fetcher: (params) => {
            const queryFilter = {
                ...params,
                sort: "name"
            }

            return apiClient.catalog.subcategory.getSubcategories(queryFilter)
        },
    };

    return (
        <CollectionPage
            title={t("catalog.subcategories.title")}
            table={table}
            filterConfig={filterConfig}
            onExport={getTableExport(apiClient, t)}
        />
    )
}

export default SubcategoriesCollectionPage
