import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"
import { getProductsFilter } from "../../../../../../lib/collection-filters"
import EntitySelectorAction from "../../../../components/form/EntitySelectionAction"
import { useVolcanoAuth } from "../../../../../../context/VolcanoAuthContext"

const ProductSelectorAction = ({ selection, onAction, onClose }) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()

    const productsFilter = getProductsFilter(
        apiClient, 
        t, 
        user, 
        true, 
        {
            "not_rate_group_id": selection.id,
            "rates_published": 0,
        }
    )

    return (
        <EntitySelectorAction
            table={productsFilter.table}
            multiple={true}
            valueFormatter={productsFilter.valueFormatter}
            onAction={onAction}
            onClose={onClose}
        />
    )
}


export default ProductSelectorAction