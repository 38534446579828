import ProductRatesPublicationForm from "../../../actions/ProductRatesPublicationForm"

const productContainerProductsEdit = ({ selection, onAction, onCancel }) => {

    selection.date_from = new Date(selection.date_from)
    selection.date_to = new Date(selection.date_to)
    selection.valid_from = new Date(selection.valid_from)
    selection.config.hide_intermediaries = Number(selection.config.hide_intermediaries)
    selection.config.only_modified_products = Number(selection.config.only_modified_products)

    const onConfirm = (result) => {
        onAction(
            {
                id: result.id,
                description: result.description,
                valid_from: result.valid_from,
                date_from: result.date_from,
                date_to: result.date_to,
                config: {
                    hide_intermediaries: parseInt(result.config.hide_intermediaries) === 1
                }
            }
        )
    }
    return (
        <ProductRatesPublicationForm
            selection={selection}
            onAction={onConfirm}
            mode='update'
        />
    )
}

export default productContainerProductsEdit