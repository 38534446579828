import { useTranslation } from "react-i18next"
import { createCallbackField, createField } from "../../../../../../../components/helpers/fieldHelper"
import ArrayDataTable from "../../../../../../../components/Datatable/ArrayDataTable";
import constants from "../../../../../../../components/entity/constants"
import DisfrutareLink from "../../../../../../../components/Volcano/Disfrutare/DisfrutareLink";
import availableRoutes from "../../../../../../../routes/availableRoutes";

const ExperienceSubcategories = ({ entity }) => {
    const { t } = useTranslation("vbms")

    const columns = [
        createField(
            "id",
            t("catalog.experiences.fields.id"),
            constants.STRING_TYPE
        ),
        createCallbackField(
            "name",
            t("catalog.experiences.tabs.subcategories.fields.name"),
            constants.TEXT_TYPE,
            (value, entity) => <DisfrutareLink href={availableRoutes.disfrutare_subcategory_view.path.replace(":entityId", entity.id)}>{entity.name}</DisfrutareLink>
        ),
    ]

    if (!entity) {
        return null
    }

    return (
        <ArrayDataTable
            data={entity.subcategories}
            columns={columns}
        />
    )
}

export default ExperienceSubcategories
