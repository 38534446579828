
import constants from "../../../../components/entity/constants"
import { createField } from "../../../../components/helpers/fieldHelper"

export const getTableFilter = (t) => {
    return [
        createField(
            "name",
            t("catalog.rate_groups.fields.name"),
            constants.STRING_TYPE
        ),
        // createField(
        //     "protected",
        //     t("catalog.rate_groups.fields.config_protected"),
        //     constants.BOOLEAN_TYPE
        // ),
    ]
}