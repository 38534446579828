
import constants from "../../../../components/entity/constants"
import { createField } from "../../../../components/helpers/fieldHelper"

export const getTableFilter = (t) => {
    return [
        createField(
            "description",
            t("catalog.product_rates_publications.fields.description"),
            constants.STRING_TYPE
        ),
        createField(
            "valid_from",
            t("catalog.product_rates_publications.fields.valid_from"),
            constants.DATE_TYPE
        ),
        createField(
            "date_from",
            t("catalog.product_rates_publications.fields.date_from"),
            constants.DATE_TYPE
        ),
        createField(
            "date_to",
            t("catalog.product_rates_publications.fields.date_to"),
            constants.DATE_TYPE
        ),
        createField(
            "created",
            t("common.fields.created"),
            constants.DATE_TYPE
        ),
    ]
}