import { AsyncArrayDataTableProvider } from "../entity/context/collection/collection.context"
import DataTable from "./DataTable"

const AsyncArrayDataTable = (props) => {

    return (
        <AsyncArrayDataTableProvider fetcher={props.fetcher}>
            <DataTable
                selection={props?.selection}
                filter={props?.filter}
                groupBy={props?.groupBy}
                columns={props?.columns}
                actions={props?.actions}
                hideFilter={true}
                selectionMode={props?.selectionMode}
                highlight={props?.highlight}
            />
        </AsyncArrayDataTableProvider>
    )
}

export default AsyncArrayDataTable
