import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../../layout/templates/CollectionPage/CollectionPage"
import { getTableFilter } from "./ProductRatesPublicationsCollection.filter"
import { getTableActions } from "./ProductRatesPublicationsCollection.actions"
import { getTableColumns } from "./ProductRatesPublicationsCollection.columns"

const ProductRatesPublicationsCollectionPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()

    const filterConfig = getTableFilter(t)

    const actions = getTableActions(t, apiClient, volcanoAuth.user)

    const table = {
        actions: actions,
        columns: getTableColumns(t),
        fetcher: (params) => {
            return apiClient.catalog.productRatesPublication.getProductRatesPublications(params)
        },
    }

    return (
        <CollectionPage
            title={t("catalog.product_rates_publications.title")}
            subtitle={t("catalog.product_rates_publications.subtitle")}
            actions={actions.filter((action) => action.scope === "page")}
            table={table}
            filterConfig={filterConfig}
        />
    )
}

export default ProductRatesPublicationsCollectionPage