import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"
import { useCallback } from 'react'
import _get from "lodash/get"
import BaseBookingProductChange from "./BaseBookingProductChange"

const BookingProductChange = ({ selection, onAction, onCancel }) => {
    const { apiClient, siteConfig } = useVolcanoApiClient()

    const bookingPax = selection.product_rates.reduce((acc, rate) => acc + rate.qty, 0)

    const experiencesFetcher = useCallback((categoryId) => {
        return apiClient.experience
            .getExperiences({
                limit: 1000,
                state: "all",
                collaborator_id: _get(selection, "order.collaborator.id", null),
                site_id: siteConfig.site_id,
                subcategory_id: categoryId
            })
            .then((result) => 
                result.getItems())
    }, [apiClient.experience, siteConfig.site_id, selection])

    const productsFetcher = useCallback((experienceId) => {
        return apiClient.booking.getProductsForChange(experienceId, [selection])
    }, [apiClient.booking, selection.id])

    const handleOnConfirm = (data) => {
        onAction({
            locator: selection.locator,
            ...data,
        })
    }

    return (
        <BaseBookingProductChange
            booking={{...selection, qty: bookingPax}}
            experiencesFetcher={experiencesFetcher}
            productsFetcher={productsFetcher}
            onConfirm={handleOnConfirm}
            state={selection.product.state}
        />
    )
}

export default BookingProductChange