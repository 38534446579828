import { useParams } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useNavigate } from 'react-router-dom'
import { useVolcanoAuth } from '../../../../context/VolcanoAuthContext'
import { createCallbackField } from '../../../../components/helpers/fieldHelper'
import constants from '../../../../components/entity/constants'
import AsyncEntityPage from "../../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { getHeaderContent } from './VenuePage.header'
import { getTabNavigationItems } from './VenuesPage.tabs'
import { getActions } from './VenuePage.actions'
import userPermissions from '../../../../lib/user-permissions'


const VenuePage = () => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()
    const navigate = useNavigate()

    const fetcher = () => apiClient.venue.getVenue(entityId).then((item) => {return item})

    console.log("permission:", user.hasPermission(userPermissions.PERM_FACILITY_BOOK_LINES_VIEW))

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "key",
                t("venues.form.title"),
                constants.STRING_TYPE,
                (entity, values) => t("venues.form.title", { name: values.name })
            )}
            headerContent={getHeaderContent(t, user)}
            tabNavigationItems={getTabNavigationItems(t, apiClient, user)}
            actions={getActions(apiClient, t, {}, navigate, entityId)}
        />
    );
}

export default VenuePage;