import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"
import { createField, createLinkField, createMultipleLinksField, productContainerTypeTypeMapping } from "../../../../../../components/helpers/fieldHelper"
import constants from "../../../../../../components/entity/constants"
import { Visibility } from "@mui/icons-material"
import ProductContainerReferenceProductRates from "../../../../ProductRatesPublications/ProductRatesPublicationPage/components/actions/ProductContainerReferenceProductRates"
import AsyncArrayDataTable from "../../../../../../components/Datatable/AsyncArrayDataTable"

const ProductContainerReferences = ({ entity }) => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")

    const columns = [
        createMultipleLinksField(
            createField(
                "name",
                t("common.name"),
                constants.STRING_TYPE
            ),
            [
                {
                    route: "rate_groups",
                    field: "id",
                    condition: (entity) => entity.container_type === "rate_group",
                },
                {
                    route: "intermediaries",
                    field: "id",
                    condition: (entity) => entity.container_type === "intermediary",
                },
            ]
        ),
        createField(
            "container_type",
            t("catalog.product_containers.fields.container_type"),
            constants.STRING_TYPE,
            productContainerTypeTypeMapping(t)
        ),
    ]

    const fetcher = (params) => {
        // load related rate groups and intermediaries
        const promises = [
            apiClient.catalog.rateGroup.getRateGroups({product_id: entity.id, limit: 1000}),
            apiClient.intermediary.getIntermediaries({product_id: entity.id, limit: 10}),
        ]

        // merge results in a single array
        return Promise.all(promises).then(([rateGroups, intermediaries]) => {
            const rateGroupData = rateGroups.getItems().map((rateGroup) => ({
                id: rateGroup.id,
                name: rateGroup.name,
                container_type: "rate_group",
            }))

            const intermediaryData = intermediaries.getItems().map((intermediary) => ({
                id: intermediary.id,
                name: intermediary.name,
                container_type: "intermediary",
            }))

            return [
                ...rateGroupData,
                ...intermediaryData,
            ]
        })        
    }

    const actions = [{
        id: "view",
        title: t("common.open"),
        scope: "row",
        icon: Visibility,
        withDialog: {
            title: (container) => t("catalog.product_containers.actions.product_container_reference.view_products.modal_title", { name: container.name }),
            content: (props) => <ProductContainerReferenceProductRates selection={props.selection} filterParams={{product_id: entity.id, rates_published: 0}} hideFilter={true} />,
            maxWidth: "md",
            closeIcon: true,
        },
        onExecute: (container, progressHandler, onSuccess, onError) => {},
    }]


    if (!entity) {
        return null;
    }

    return (
        <AsyncArrayDataTable
            fetcher={fetcher}
            columns={columns}
            actions={actions}
        />
    )
}

export default ProductContainerReferences