import { createExportField, createCommonBooleanMapping } from '../../../../components/helpers/fieldHelper';

export const getTableExport = (apiClient, t) => {
    return (filter, progressHandler) => {
        
        apiClient.catalog.subcategory.exportSubcategories(
            [
                createExportField(
                    "id",
                    "id",
                ),
                createExportField(
                    "name",
                    t("common.name"),
                ),
                createExportField(
                    "sites",
                    t("common.websites"),
                    null,
                    (booking) => booking.sites.map(item => item.name).join(',')
                ),
                createExportField(
                    "visible",
                    t("common.visible"),
                    createCommonBooleanMapping(t)
                ),
            ],
            filter,
            progressHandler
        )
    }
}
