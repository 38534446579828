import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material"
import RatesContainerEditor from "./RatesContainerEditor"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import DataWrapper from "../../../../components/Display/DataWrapper"
import BaseActionsGroup from "../../../../components/action/BaseActionsGroup"
import _isFunction from "lodash/isFunction"
import { useCache } from "../../../../context/cache.context"


const processQtyRestrictionValue = (value, defaultValue) => {
    if (value === null || value === undefined || value === "" || value < 0) {
        return defaultValue
    }

    return parseInt(value)
}

const initResult = (selection) => {

    let rates = selection.rates
    // set default ratges for empty enterprise container
    if (selection.container.container_type === "enterprise" && rates.length === 0) {
        rates = ["16,19", "14,19", "16,18", "14,18"].map((customerTypeIds) => ({
            id: -(Math.floor(Math.random() * 10000)),
            customer_types: {
                name: "",
                ids: customerTypeIds,
            },
            retail_price: {
                value: 0,
            },
            net_price: {
                value: 0,
            },
            commission_percentage: 0,
            active: true,
            qty_restrictions: {
                min: 0,
                max: null,
            },
        }))
    }

    return {
        container_type: selection.container.container_type,
        experience_id: selection.experience.id,
        experience_name: selection.experience.name,
        active: selection.active,
        visibility: selection.visibility,
        config: {
            show_in_pdf: selection?.container_configuration?.show_in_pdf || false,
            qty_restrictions: {
                min: processQtyRestrictionValue(selection?.container_configuration?.qty_restrictions?.min, null),
                max: processQtyRestrictionValue(selection?.container_configuration?.qty_restrictions?.max, null),
            },
            customer_types_restrictions: selection?.container_configuration?.customer_types_restrictions || [],
        },
        rates: rates.map(rate => ({
            id: rate.id,
            name: rate.customer_types.name,
            customer_type_ids: rate.customer_types.ids,
            retail_price: parseFloat(rate.retail_price.value),
            net_price: selection.container_type !== "enterprise" ? parseFloat(rate.net_price.value) : null,
            commission_rate: selection.container_type !== "enterprise" ? parseFloat(rate.commission_percentage * 100) : null,
            active: rate.active,
            qty_restrictions: { 
                min: processQtyRestrictionValue(rate?.qty_restrictions?.min, 0),
                max: processQtyRestrictionValue(rate?.qty_restrictions?.max, null),
             },
        })),
        isValid: true,
    }
}

const initProductContainerResult = (value) => ({
    container_type: value.container_type,
    experience_id: value.experience_id,
    experience_name: value.experience_name,
    active: value.active,
    visibility: value.visibility,
    config: {
        ...value.config,
    },
})

const getQtyRestrictionText = (value) => {
    if (value === null || value === undefined || value === "" || value <= 0) {
        return ["catalog.rates_table.rates.config.default_pax_unlimited"]
    }

    return ["catalog.rates_table.rates.config.default_pax_value", { value: value }]
}

const findCustomerType = (customerTypeGroups, id) => {
    // add group id to each customer type and return the customer type that matches the id
    id = parseInt(id)
    for (const group of customerTypeGroups) {
        const customerType = group.customer_types.find((customerType) => customerType.id === id)
        if (customerType) {
            return { ...customerType, id: customerType.id + "", group_id: group.id }
        }
    }

    return null
}

const RatesRestrictionsForm = ({ selection, rates, onChange }) => {
    const { t } = useTranslation("vbms")
    const [customerTypeGroups, setCustomerTypeGroups] = useState(null)
    const cache = useCache()

    useEffect(() => {
        if (customerTypeGroups === null) {
            cache.get("customer_type_groups", []).then((items) => setCustomerTypeGroups(items))
        }
    }, [customerTypeGroups, cache])

    if (!customerTypeGroups) {
        return null
    }

    const onSelectionChangeHandler = (customerTypeId, selected) => {
        // update selection array (add or remove customer type id)
        const ctId = parseInt(customerTypeId)
        let newSelection = [...selection]
        if (selected) {
            newSelection.push(ctId)
        } else {
            newSelection = newSelection.filter((id) => id !== ctId)
        }

        // remove repeated values
        newSelection = newSelection.filter((value, index, self) => self.indexOf(value) === index)

        onChange(newSelection)
    }

    // get repeated customer types in active rates
    const availableCustomerTypes = rates
        // filter only active rates
        .filter(rate => rate.active)
        .map(rate => (rate.customer_type_ids || "").split(","))
        .flat()
        // filter only repeated values
        .filter((value, index, self) => self.indexOf(value) !== index)
        // filter unique values
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((id) => findCustomerType(customerTypeGroups, id))
        // filter only customer types of group age (5)
        .filter((customerType) => customerType.group_id === 5)

    if (availableCustomerTypes.length === 0) {
        return null
    }

    return (
        <Stack spacing={1}>
            <FormGroup aria-label="position" row>
                {availableCustomerTypes.map((customerType) => (
                    <FormControlLabel
                        key={customerType.id}
                        control={
                            <Checkbox
                                checked={selection.some((customerTypeId) => customerTypeId === parseInt(customerType.id))}
                                onChange={(e) => onSelectionChangeHandler(customerType.id, e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={t("catalog.rates_table.form.required_customer_type", {name: customerType.name})}
                        labelPlacement="start"
                    />

                ))}
            </FormGroup>
        </Stack>
    )
}

const ProductContainerForm = ({ product, defaultConfig, onChange }) => {
    const { t } = useTranslation("vbms")

    const onExperienceChangeHandler = (e) => {
        onChange({
            ...initProductContainerResult(product),
            experience_id: e.target.value,
        })
    }

    const onActiveChangeHandler = (e) => {
        onChange({
            ...initProductContainerResult(product),
            active: e.target.checked,
        })
    }

    const onVisibilityChangeHandler = (e) => {
        onChange({
            ...initProductContainerResult(product),
            visibility: e.target.checked ? "public" : "private",
            config: {
                ...product.config,
                show_in_pdf: e.target.checked,
            }
        })
    }

    const onShowPdfChangeHandler = (e) => {
        onChange({
            ...initProductContainerResult(product),
            config: {
                ...product.config,
                show_in_pdf: e.target.checked,
            }
        })
    }

    const onQtyRestrictionChangeHandler = (field, value) => {
        let restriction = parseInt(value) || null
        if (restriction < 0) {
            restriction = null
        }

        onChange({
            ...initProductContainerResult(product),
            config: {
                ...product.config,
                qty_restrictions: {
                    ...product.config.qty_restrictions,
                    [field]: restriction,
                }
            }
        })
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        value="active"
                        control={
                            <Checkbox
                                checked={product.active}
                                onChange={onActiveChangeHandler}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={t("catalog.rates_table.rates.active")}
                        labelPlacement="start"
                        sx={{ ml: 0, mr: 2 }}
                    />
                    <FormControlLabel
                        value="visibility"
                        control={
                            <Checkbox
                                checked={product.visibility === "public"}
                                onChange={onVisibilityChangeHandler}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={t("common.visibility.public")}
                        labelPlacement="start"
                        sx={{ ml: 0, mr: 2 }}
                    />
                    {product.container_type != "enterprise" && (
                        <FormControlLabel
                            value="include_pdf"
                            control={
                                <Checkbox
                                    checked={product?.config?.show_in_pdf}
                                    onChange={onShowPdfChangeHandler}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={t("catalog.product_containers.fields.config.show_in_pdf")}
                            labelPlacement="start"
                            sx={{ ml: 0 }}
                        />
                    )}
                </FormGroup>
            </Grid>
            {false && (<Grid item xs={6}>
                <FormControl size="small" fullWidth>
                    <InputLabel id="experience-label">{t("catalog.products.fields.experience")}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={product.experience_id}
                        label={t("catalog.products.fields.experience")}
                        onChange={onExperienceChangeHandler}
                        variant="outlined"
                    >
                        <MenuItem value={product.experience_id}>{product.experience_name}</MenuItem>
                        <MenuItem value={2}>Experiencia 2</MenuItem>
                        <MenuItem value={3}>Experiencia 3</MenuItem>
                    </Select>
                </FormControl>
            </Grid>)}
            <Grid item xs={6}>
                <Stack spacing={1} direction="row">
                    <TextField
                        label={t("catalog.rates_table.rates.config.min_pax")}
                        type="number"
                        value={product?.config?.qty_restrictions?.min}
                        onChange={(e) => onQtyRestrictionChangeHandler("min", e.target.value)}
                        variant="outlined"
                        size="small"
                        helperText={t(...getQtyRestrictionText(defaultConfig?.qty_restrictions?.min))}
                    />
                    <TextField
                        label={t("catalog.rates_table.rates.config.max_pax")}
                        type="number"
                        value={product?.config?.qty_restrictions?.max}
                        onChange={(e) => onQtyRestrictionChangeHandler("max", e.target.value)}
                        variant="outlined"
                        size="small"
                        helperText={t(...getQtyRestrictionText(defaultConfig?.qty_restrictions?.max))}

                    />
                </Stack>
            </Grid>
        </Grid>
    )
}

const ProductContainerEditor = ({ selection, onAction, onClose }) => {
    const { t } = useTranslation("vbms")
    const [result, setResult] = useState(initResult(selection))

    const onProductChangeHandler = (product) => {
        setResult((prevResult) => ({
            ...prevResult,
            active: product.active,
            visibility: product.visibility,
            config: product.config,
        }))
    }

    const onRatesChangeHandler = (rates, isValid) => {
        setResult((prevResult) => ({
            ...prevResult,
            rates: rates,
            isValid: isValid,
        }))
    }

    const onRatesRestrictionsChangeHandler = (customerTypesRestrictions) => {
        setResult((prevResult) => ({
            ...prevResult,
            config: {
                ...prevResult.config,
                customer_types_restrictions: customerTypesRestrictions,
            }
        }))
    }

    const actions = []

    if (result.isValid) {
        actions.push({
            id: "save",
            title: t("common.save"),
            onExecute: () => {
                if (_isFunction(onAction)) {
                    // process commission rates
                    const rates = result.rates.map(rate => ({
                        ...rate,
                        commission_percentage: rate.commission_rate / 100,
                    }))

                    return onAction({
                        id: selection.id,
                        name: selection.name,
                        data: {
                            id: selection.id,
                            ...result,
                            rates: rates,
                        }
                    })
                        .then((product) => {
                            onClose()
                            return product
                        })
                }
            }
        })
    }

    actions.push({
        id: "cancel",
        title: t("common.cancel"),
        onExecute: () => {
            if (_isFunction(onClose)) {
                return onClose()
            }
        },
    })

    return (
        <Stack spacing={2}>
            <DataWrapper
                title={selection.name}
                subtitle={t("catalog.rates_table.form.container_description", { container_name: selection.container.name })}
                subtitleVariant="text"
            >
                <Stack spacing={2}>
                    <ProductContainerForm
                        product={result}
                        defaultConfig={{
                            qty_restrictions: selection?.qty_restrictions || {}
                        }}
                        onChange={onProductChangeHandler}
                    />
                    <RatesContainerEditor
                        withNetPrice={selection.container.container_type !== "enterprise"}
                        editCustomerTypes={!selection.container.is_published && selection.container.container_type === "enterprise"}
                        rates={result.rates}
                        onChange={onRatesChangeHandler}
                    />
                    {selection.container.container_type === "enterprise" && (
                        <RatesRestrictionsForm selection={result.config?.customer_types_restrictions} rates={result.rates} onChange={onRatesRestrictionsChangeHandler} />
                    )}
                </Stack>
            </DataWrapper>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <BaseActionsGroup
                    selection={result}
                    actions={actions}
                    actionsNumberDesktop={2}
                />
            </Box>
        </Stack>
    )
}

export default ProductContainerEditor