import constants from '../../../components/entity/constants'
import { createArrayField, createDataSetFacet, createFacetField, createField, createFacetsContainer, createCallbackField, languagesCode2, createLanguagesMapping, createObjectStatusField, createLinkField } from '../../../components/helpers/fieldHelper';
import _has from "lodash/has"
import userPermissions from '../../../lib/user-permissions'
import { Link } from '@mui/material'

export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("intermediaries.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "collaborator_id",
            t("intermediaries.fields.collaborator_id"),
            constants.STRING_TYPE
        ),
        createField(
            "location_type.name",
            t("intermediaries.fields.location_type"),
            constants.TEXT_TYPE,
            null,
            (entity) => entity.location_type !== null
        ),
        createField(
            "name",
            t("intermediaries.fields.comercial_name"),
            constants.STRING_TYPE
        ),
        createCallbackField(
            "web",
            t("intermediaries.fields.web"),
            constants.STRING_TYPE,
            (value) => {
                const protocols = ["http://", "https://"]
                const url = value

                if (value) {
                    protocols.forEach((protocol) => {
                        if (value.startsWith(protocol)) {
                            value = value.substring(protocol.length)
                            return
                        }
                    });
                }

                return <Link href={"http://" + value} target="_blank">{url}</Link>
            }
        ),
        createField(
            "language",
            t("intermediaries.fields.language"),
            constants.STRING_TYPE,
            createLanguagesMapping(t, languagesCode2(), true)
        ),
        createField(
            "business_group",
            t("intermediaries.fields.business_group"),
            constants.STRING_TYPE
        ),
        createField(
            "delegation",
            t("intermediaries.fields.delegation"),
            constants.STRING_TYPE
        ),
        createField(
            "delegation_code",
            t("intermediaries.fields.delegation_code"),
            constants.STRING_TYPE
        ),
        createField(
            "created",
            t("common.fields.created"),
            constants.DATETIME_TYPE
        ),
    ]),
    createDataSetFacet(t("intermediaries.view.facet_marketing_management"), [
        createField(
            "enterprise.name",
            t("common.enterprise"),
            constants.TEXT_TYPE
        ),
        createField(
            "salesman.name",
            t("intermediaries.fields.crm_salesman"),
            constants.TEXT_TYPE,
            null,
            (entity) => _has(entity, "salesman") && user.hasPermission(userPermissions.PERM_CRM_SALESMAN_VIEW)
        ),
        createLinkField(
            createField(
                "rate_group.name",
                t("intermediaries.fields.parent_rates_collaborator"),
                constants.TEXT_TYPE,
                null,
                () => !user.isIntermediary()
            ),
            {
                route: "rate_groups",
                field: "rate_group.id",
            }
        ),
        createField(
            "intermediary_type.name",
            t("intermediaries.fields.intermediary_type"),
            constants.TEXT_TYPE
        ),
        createField(
            "seller_type.name",
            t("intermediaries.fields.seller_type"),
            constants.TEXT_TYPE,
            null,
            (entity) => entity.seller_type !== null
        ),
    ]),
    createDataSetFacet(
        t("intermediaries.view.facet_admin_management"),
        [
            createField(
                "billing_type.name",
                t("intermediaries.fields.billing_type"),
                constants.TEXT_TYPE
            ),
            createField(
                "billing_period.name",
                t("intermediaries.fields.billing_period"),
                constants.TEXT_TYPE,
                null,
                (entity) => _has(entity, "payment_method") && entity.payment_method !== null && entity.payment_methods.some(method => method.id === 1) // Credit
            ),
            createField(
                "payment_guarantee.name",
                t("intermediaries.fields.payment_guarantee"),
                constants.TEXT_TYPE
            ),
            createField(
                "account_id",
                t("intermediaries.fields.account_id"),
                constants.TEXT_TYPE
            )
        ],
        () => !user.isIntermediary()
    ),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createCallbackField(
                "state.id",
                t("intermediaries.fields.state"),
                constants.STRING_TYPE,
                (value) => t("intermediaries.constants.state." + value)
            ),
        }),
        createFacetField("object_status", {
            size: "small",
            field:
                createArrayField(
                    createField(
                        "payment_methods",
                        t("intermediaries.fields.payment_methods"),
                        constants.ARRAY_TYPE
                    ),
                    "name"
                ),
        }),
        createFacetField("object_status", {
            size: "small",
            condition: (entity) => entity?.payment_token,
            field:
                createObjectStatusField(
                    createField(
                        "payment_token.valid",
                        t("intermediaries.fields.payment_token"),
                        constants.STRING_TYPE,
                        {
                            true: t("common.states.valid"),
                            false: t("common.states.invalid"),
                        }
                    ),
                    null,
                    (value) => value ? "positive" : "negative"
                ),
        })],
    )
]