import _isArray from 'lodash/isArray';

export const filterAvailableActions = (actions, selection = null, availableActions = null) => {
    if (!_isArray(actions)) {
        return null
    }

    return actions
        .filter((action) => availableActions ? availableActions.includes(action.id) : true)
        .filter((action) => action.condition ? (selection ? action.condition(selection) : action.condition()) : true)
}
