import { createField, createCommonStateMapping, createLinkField, createArrayField } from '../../../../components/helpers/fieldHelper'
import constants from "../../../../components/entity/constants"
import _get from "lodash/get"

export const getTableColumns = (t, user) => ([
    createField(
        "id",
        "ID",
        constants.STRING_TYPE
    ),
    createLinkField(
        createField(
            "name",
            t("catalog.experiences.fields.name"),
            constants.STRING_TYPE,
        ),
        {
            route: "catalog_experiences",
            field: "id",
        }
    ),
    createArrayField(
        createField(
            "sites",
            t("common.websites"),
            constants.ARRAY_TYPE
        ),
        "name"
    ),
    createField(
        "main_subcategory.name",
        t("catalog.experiences.fields.main_subcategory"),
        constants.TEXT_TYPE
    ),
    createField(
        "state",
        t("common.visible"),
        constants.STRING_TYPE,
        createCommonStateMapping(t)
    ),
])
