import { useTranslation } from "react-i18next"
import constants from "../../../../components/entity/constants"
import { createField, createFormField, createFormFieldset, createPercentageField } from "../../../../components/helpers/fieldHelper"
import EntityEditForm from "../../../actions/EntityEditForm"


const RateGroupForm = ({ selection, onAction }) => {
    const { t } = useTranslation("vbms")

    const entity = selection || {
        name: "",
        config: {
            default_commission: 0
        }
    }

    const formContent = [
        createFormFieldset(null, [
            createFormField(
                createField(
                    "name",
                    t("catalog.rate_groups.fields.name"),
                    constants.STRING_TYPE
                ),
                true
            ),
            {
                ...createFormField(
                    createPercentageField(
                        createField(
                            "config.default_commission",
                            t("catalog.rate_groups.fields.config_default_commission"),
                            constants.PERCENTAGE_TYPE
                        )
                    ),
                    true
                ),
                max_width: "100%"
            },
        ])
    ]

    return (
        <EntityEditForm
            selection={entity}
            formContent={formContent}
            onAction={onAction}
        />
    )
}

export default RateGroupForm