import { downloadFile, getSuggestedFilename } from "../../../lib/utils"

export const getShareActions = (user, apiClient, entityId, t) => [
    {
        id: "link",
        title: t("common.actions.share.options.link"),
        onExecute: (site, progressHandler, onSuccess, onError) => {
            navigator.clipboard.writeText(window.location.href)
            onSuccess(t("common.actions.share.confirm"))
        },
    },
    {   
        id: "download_pdf",
        title: t("catalog.rate_groups.download"),
        onExecute: (site, progressHandler, onSuccess, onError) => {
            apiClient.catalog.rateGroup.getRateGroupPdf(site.rate_group.id).then(pdf => {
                downloadFile(pdf, `Catalog ${getSuggestedFilename(site.base_url)}.pdf`)
            })
        },
        condition: (site) => site.rate_group
    }
]