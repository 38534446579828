import { createTabBarItem } from '../../../components/helpers/fieldHelper';
import SiteFeaturedProducts from './components/tabs/SiteFeaturedProducts';
import SiteProducts from './components/tabs/SiteProducts';
import SitePublications from './components/tabs/SitePublications';

export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("catalog.rate_groups.view.navbar.products"),
        SiteProducts,
    ),
    createTabBarItem(
        t("sites.view.navbar.featured_products"),
        ({ entity, data }) => (
            <SiteFeaturedProducts
                data={data.getItems()}
                entity={entity}
            />
        ),
        (site) => apiClient.content.site.getFeaturedProducts(site.id).catch((err) => { }),
    ),
    createTabBarItem(
        t("sites.view.navbar.publication_history"),
        ({ entity, data }) => (
            <SitePublications
                data={data.getItems()}
                entity={entity}
            />
        ),        
        (site) => apiClient.content.site.getEntityActionLogs(site.id).catch((err) => { }),
        (site) => site.hasAction("publish")
    ),
];