import { useEffect } from "react"
import Loading from "../../../components/Display/Loading"
import useSnackBars from "../../../components/Snackbar/snack-bar.context"
import { AsyncContextProvider, useAsyncContent } from "../../../context/async.context"
import EntityFormPage from "../EntityFormPage/EntityFormPage"

const AsyncEntityFormPageContent = (props) => {
  const { data, error } = useAsyncContent()
  const { addAlert } = useSnackBars()

  useEffect(() => {
    if (error != null) {
      addAlert(`Error [${error.type}]: ${error.message}`, "error")
    }
  }, [error, addAlert]);

  return (
    <EntityFormPage entity={data} {...props} />
  )
}

const AsyncEntityFormPage = (props) => {

  return (
    <AsyncContextProvider fetcher={props.fetcher} loadComponent={Loading}>
      <AsyncEntityFormPageContent {...props} />
    </AsyncContextProvider>
  )
}

export default AsyncEntityFormPage
