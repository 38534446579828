import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from '../../../../context/VolcanoAuthContext'
import { useNavigate } from 'react-router-dom'
import { useCache } from "../../../../context/cache.context"
import CollectionPage from "../../../../layout/templates/CollectionPage/CollectionPage"
import { getTableColumns } from "./ExperiencesCollection.columns"
import { getTableFilter } from "./ExperiencesCollection.filter"
import { getActions, getTableActions } from "./ExperiencesCollection.actions"
import { getTableExport } from "./ExperiencesCollection.export"
import _get from "lodash/get"
import _isArray from "lodash/isArray"

const ExperiencesCollectionPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const cache = useCache()

    const filterConfig = getTableFilter(t, apiClient, volcanoAuth.user, cache)

    const table = {
        columns: getTableColumns(t, volcanoAuth.user),
        actions: getTableActions(
            apiClient,
            t,
            { scope: "row", icon: true, reloadOnSuccess: true, showLoading: true },
            navigate,
            volcanoAuth.user
        ),
        fetcher: (params) => {
            let subcategoryFilter = [];
            if (_get(params, "subcategory_id", null)) {
                if (_isArray(_get(params, "subcategory_id", null))) {
                    _get(params, "subcategory_id", null).forEach(col => {
                        subcategoryFilter.push(col.id)
                    });
                } else {
                    subcategoryFilter.push(_get(params, "subcategory_id", null));
                }
            }

            const queryFilter = {
                ...params,
                sort: 'name',
                subcategory_id: subcategoryFilter
            }

            return apiClient.catalog.experience.getExperiences(queryFilter)
        },
    };

    return (
        <CollectionPage
            title={t("catalog.experiences.title")}
            table={table}
            filterConfig={filterConfig}
            actions={getActions(t, navigate, volcanoAuth.user)}
            onExport={getTableExport(apiClient, t)}
        />
    )
}

export default ExperiencesCollectionPage;