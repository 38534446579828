
import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import { Alert, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import DataSetWrapper from "../../../Display/DataSetWrapper"
import DataWrapper from "../../../Display/DataWrapper"
import KeyValue from "../../../Display/KeyValue"
import ObjectStatus from "../../../Display/ObjectStatus"
import Text from "../../../Display/Text"
import PickupFieldValue from "../../../entity/PickupFieldValue"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import { getCurrencyLocale } from '../../../../lib/utils';

const intermediaryFields = [
    {
        field: "intermediary.name",
        label: "booking_widget.summary.intermediary",
        type: "text",
    },
    {
        field: "office.name",
        label: "booking_widget.summary.office",
        type: "text",
    },
    {
        field: "salesman.name",
        label: "booking_widget.summary.salesman",
        type: "text",
    },
]

const bookingFields = [
    {
        field: "product.experience.name",
        label: "booking_widget.summary.experience",
        type: "text",
    },
    {
        field: "product.name",
        label: "booking_widget.summary.product",
        type: "text",
    },
    {
        field: "date",
        label: "booking_widget.summary.date",
        type: "date",
        value: (selection) => ({
            date: _get(selection, "date"),
            session: _get(selection, "session")
        }),
    },
]

const clientFields = [
    {
        field: "first_name",
        label: "bookings.fields.order.customer.first_name",
        type: "text",
    },
    {
        field: "last_name",
        label: "bookings.fields.order.customer.last_name",
        type: "text",
    },
    {
        field: "email",
        label: "bookings.fields.order.customer.email",
        type: "text",
    },
    {
        field: "phone",
        label: "bookings.fields.order.customer.phone",
        type: "text",
    },
]

const participantFields = [
    {
        field: "first_name",
        label: "bookings.fields.product_rates.participant.first_name",
        type: "text",
    },
    {
        field: "last_name",
        label: "bookings.fields.product_rates.participant.last_name",
        type: "text",
    },
    {
        field: "nif",
        label: "bookings.fields.product_rates.participant.id_card",
        type: "text",
    },
]

const Participants = ({ lineItems, participants }) => {
    const { t } = useTranslation("vbms")

    if (_isEmpty(participants)) {
        return null
    }

    const renderParticipants = () => {
        let index = 1
        return (
            <Grid container spacing={1}>
                {participants
                    .map((lineItemParticipants) => {
                        const lineItem = lineItems.find((item) => item.id === lineItemParticipants.line_item_id)
                        return lineItemParticipants.participants.map((participant) => {
                            const rate = participant.rate.split('-')
                            participant.rateName = lineItem.rates.find((item) => item.rate.id === parseInt(rate[0])).rate.name
                            const title = t("booking_widget.steps.client.participant", {
                                count: index++,
                                rate_name: participant.rateName,
                            })
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <DataSetWrapper
                                        title={title}
                                        data={participant}
                                        config={participantFields}
                                    />
                                </Grid>
                            )
                        })
                    })
                    .flat()
                }
            </Grid>
        )
    }

    return (<>{renderParticipants()}</>)
}

const RatesTable = ({ rates }) => {
    const { t } = useTranslation("vbms")

    if (_isEmpty(rates)) {
        return null
    }

    return (
        <TableContainer component={Box}>
            <Table
                size="small"
                sx={{
                    '& td, & th': { border: 0 },
                    '& td:last-child, & th:last-child': { pr: 0 },
                    '& td:first-of-type, & th:first-of-type': { pl: 0 },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell align="right">{t("booking_widget.steps.rates.rate_name")}</TableCell>
                        <TableCell align="right">{t("booking_widget.steps.rates.rate_qty")}</TableCell>
                        <TableCell align="right">{t("booking_widget.steps.rates.rate_price")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rates.map((rate, index) => (
                        <TableRow key={index}>
                            <TableCell align="right"><Text>{rate.name}</Text></TableCell>
                            <TableCell align="right"><Text>x {rate.qty}</Text></TableCell>
                            <TableCell align="right"><Text>{rate.pvp}</Text></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const BookingSummary = ({ selection, pickupData }) => {
    const { step, rates } = selection || {}
    const { t, i18n } = useTranslation("vbms");
    const volcanoAuth = useVolcanoAuth()

    const priceFormatter = new Intl.NumberFormat(getCurrencyLocale(i18n.language), {
        style: "currency",
        currency: _get(selection, "cart.currency", "eur"),
    })

    const getTotalAmount = (key) => {
        let total = 0
        rates.forEach((selRate) => {
            total += selRate[key] * selRate.qty
        })

        return total
    }

    return (
        <Box
            sx={{
                '& .MuiCardContent-root': { pb: 0 },
                '& .MuiCardContent-root > *': { mb: 1 },
            }}
        >
            <DataWrapper title={t("booking_widget.summary.title")}>
                {selection?.intermediary?.id && (
                    <DataSetWrapper
                        title={t("booking_widget.summary.intermediary")}
                        data={selection}
                        config={intermediaryFields}
                    />
                )}
                {selection.product && (
                    <DataSetWrapper
                        title={t("booking_widget.summary.booking")}
                        data={selection}
                        config={bookingFields}
                    />
                )}
                <RatesTable
                    rates={rates
                        .map((selRate) => {
                            return {
                                name: selRate.name,
                                qty: selRate.qty,
                                pvp: priceFormatter.format(selRate.pvp),
                            };
                        })}
                />
                {step > 4 && (
                    <>
                        <DataSetWrapper
                            title={t("booking_widget.summary.client")}
                            data={selection.client}
                            config={clientFields}
                            direction="row"
                        />
                        <Participants
                            lineItems={_get(selection, "cart.line_items", [])}
                            participants={selection.participants}
                        />
                        {pickupData && (
                            <PickupFieldValue value={pickupData.pickup_points.find((pickupPoint) => pickupPoint.id === _get(selection, "pickup.pickup_point_id"))} />
                        )}
                    </>
                )}
                {step >= 3 && (
                    <Box
                        display="flex"
                        flexDirection="row-reverse"
                        justifyContent="space-between"
                    >
                        <KeyValue
                            label={t("booking_widget.summary.total_amount")}
                            labelVariant="title"
                        >
                            <ObjectStatus variant="neutral" size="large">
                                {priceFormatter.format(getTotalAmount("pvp"))}
                            </ObjectStatus>
                        </KeyValue>
                        {volcanoAuth.user.hasPermission("view-booking-net-price") && (
                            <KeyValue
                                label={t("booking_widget.summary.total_amount_net")}
                                labelVariant="title"
                            >
                                <ObjectStatus variant="info" size="large">
                                    {priceFormatter.format(getTotalAmount("neto"))}
                                </ObjectStatus>
                            </KeyValue>
                        )}
                    </Box>
                )}
                {_get(selection, "product.with_confirmation", false) && (
                    <Alert severity="warning">{_get(selection, "product.booking_process_information.confirmation_pending_info", t("booking_widget.summary.with_confirmation_message"))}</Alert>
                )}
            </DataWrapper >
        </Box >
    );
};

export default BookingSummary
