import { useTranslation } from "react-i18next"
import AsyncEntityForm from "../../../../../../components/form/AsyncEntityForm"
import { createFormField, createFormFieldset, createField, createAsyncSelectFormField } from '../../../../../../components/helpers/fieldHelper'
import constants from "../../../../../../components/entity/constants"
import {useVolcanoApiClient} from "../../../../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../../../../context/VolcanoAuthContext"
import { buildOptions } from "../../../../../../lib/form-options-fetchers"
import { getExperiencesFilter } from "../../../../../../lib/collection-filters"

const ProductAddForm = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")
    
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()

    const fetcher = () => new Promise((res) => res({}))
    
    const formContent = [
        createFormFieldset(null, [
                createFormField(
                    createField(
                        "name",
                        t('catalog.products.fields.name'),
                        constants.STRING_TYPE
                    ),
                    true,
                    ""
                ),
                createFormField(
                    createField(
                        "sku",
                        t('catalog.products.fields.sku'),
                        constants.STRING_TYPE
                    ),
                    true,
                    ""
                ),
                createFormField(
                    createAsyncSelectFormField(
                        "tax.id",
                        t('catalog.products.fields.tax'),
                        () => {
                                return apiClient.enterprise
                                .getTaxes(volcanoAuth.user.corporate_account.enterprise.id)
                                .then((taxes) => {
                                        return buildOptions(Object.values(taxes), t, "id", "name", false, false, false)
                                    }
                                )
                            },
                        false,
                    ),
                    true,
                ),
                {...getExperiencesFilter(apiClient, t, false), required: true},
            
        ])
    ]

    const onConfirm = (result) => {
        onAction({
                    name: result.name,
                    sku: result.sku,
                    tax: result.tax,
                    experience: { 
                            id: result.experience_id[0].id 
                    } 
                })
    }

    return (
            <AsyncEntityForm
                fetcher={fetcher}
                onSave={onConfirm}
                formContent={formContent}
            />
    )
}

export default ProductAddForm