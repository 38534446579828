import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"
import { getTableColumns } from "../../../../ProductRatesPublications/ProductRatesPublicationsCollection/ProductRatesPublicationsCollection.columns"
import { useEffect, useState } from "react"
import ArrayDataTable from "../../../../../../components/Datatable/ArrayDataTable"
import { Visibility } from "@mui/icons-material"
import { createField, productContainerTypeTypeMapping } from "../../../../../../components/helpers/fieldHelper"
import constants from "../../../../../../components/entity/constants"
import ProductContainerReferenceProductRates from "../../../../ProductRatesPublications/ProductRatesPublicationPage/components/actions/ProductContainerReferenceProductRates"
import { useVolcanoAuth } from "../../../../../../context/VolcanoAuthContext"

const ProductRatesCalendarTable = ({ entity, filterField, hideFilter }) => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const [productContainers, setProductContainers] = useState(null)
    const { user } = useVolcanoAuth()

    useEffect(() => {
        if (!entity.id) {
            return
        }

        if (productContainers !== null) {
            return
        }


        const params = {
            [filterField]: entity.id,
        }
        apiClient.catalog.productRatesPublication
            .getProductRatesPublications(params)
            .then((result) => {
                // prepare data for the grouped table
                setProductContainers(result.getItems()
                    .reduce((acc, item) => {
                        item.product_container_references.forEach((container) => {
                            acc.push({
                                ...container,
                                product_rates_publication: item,
                            })
                        })

                        return acc
                    }, []))
            })
    }, [apiClient, entity.id, productContainers])

    if (!productContainers) {
        return null
    }

    const groupedColumns = {
        field: "product_rates_publication",
        sort: (a, b) => {
            return new Date(b.entity.created) - new Date(a.entity.created) ||
                new Date(b.entity.date_from) - new Date(a.entity.date_from) ||
                new Date(b.entity.date_to) - new Date(a.entity.date_to)
        },
        columns: getTableColumns(t),
    }

    const columns = [
        createField(
            "name",
            t("common.name"),
            constants.STRING_TYPE
        ),
        createField(
            "container_type",
            t("catalog.product_containers.fields.container_type"),
            constants.STRING_TYPE,
            productContainerTypeTypeMapping(t)
        ),
    ]

    const actions = [{
        id: "view",
        title: t("common.open"),
        scope: "row",
        icon: Visibility,
        withDialog: {
            title: (container) => t("catalog.product_rates_publications.actions.product_container_reference.view_products.modal_title", { name: container.name }),
            content: (props) => <ProductContainerReferenceProductRates selection={props.selection} filterParams={{[filterField]: entity.id}} hideFilter={hideFilter} />,
            maxWidth: "md",
            closeIcon: true,
        },
        onExecute: (container, progressHandler, onSuccess, onError) => {},
    }]

    return (<ArrayDataTable
        data={productContainers}
        groupBy={groupedColumns}
        columns={columns}
        actions={actions}
    />)
}

export default ProductRatesCalendarTable