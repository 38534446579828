import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"
import { useCallback, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useDialog } from "../../../../../../components/Dialog/dialog.context";
import ProductSelector from '../../../../../../components/Volcano/ProductSelector/ProductSelector';
import { useCache } from '../../../../../../context/cache.context';
import DateSelector from '../../../../../../components/Volcano/DateSelector/DateSelector';
import { parseISO, format as formatDate, isBefore } from 'date-fns';
import ObjectStatus from '../../../../../../components/Display/ObjectStatus';


const FormButtons = ({ hideConfirm, t, onConfirm, onClose }) => {
    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center" mt={2}
        >
            <Stack direction="row" spacing={1}>
                {!hideConfirm &&
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        onClick={() => onConfirm()}>
                        {t("common.accept")}
                    </Button>}
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={() => onClose()}>
                    {t("common.cancel")}
                </Button>
            </Stack>
        </Box>
    )
}

const BaseBookingProductChange = ({ booking, experiencesFetcher, productsFetcher, onConfirm }) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { handleClose } = useDialog()
    const cache = useCache()

    const [data, setData] = useState({ 
        site_id: null,
        crm_intermediary_id: null,
        product_id: null,
        booking_date: null,
        experience_id: null 
    })
    const [dateState, setDateState] = useState({ ready: false, availability_request_end: true, match: false, selected: false })

    const categoriesFetcher = useCallback(() => {
        return apiClient.content.subcategory
            .getSubcategories(null, "summary", { 
                site_id: booking.order.site.id,
                crm_intermediary_id: booking.order?.collaborator?.crm_intermediary_id
            })
            .then((result) => result.getItems())
    }, [cache])

    const availabilityFetcher = useCallback((productId, date, fullMonth = true) => {
        return apiClient.catalog.product.getProductAvailability(productId, date, fullMonth)
    }, [apiClient])

    const handleOnProductSelection = (value) => {
        const parsedBookingDate = parseISO(booking.booking_date)
        const productId = value.product ? value.product.id : null
        const experienceId = value.experience ? value.experience.id : null
        const sharedCapacity = value.product ? value.product.shared_capacity : false
        
        if (data.product_id !== productId) {
            if (productId !== null) {
                var now = new Date()
                if (parsedBookingDate.getHours() === 0 && parsedBookingDate.getMinutes() === 0) {
                    now.setHours(0, 0, 0, 0)
                }
                now = parseISO(now.toISOString())

                if (sharedCapacity || isBefore(parsedBookingDate, now)) {
                    setDateState({ ready: false, availability_request_end: true, match: true, selected: false })
                    setData({ product_id: productId, booking_date: null, experience_id: experienceId })

                    return
                }

                setDateState({ ready: false, availability_request_end: false, match: false, selected: false })

                availabilityFetcher(productId, parsedBookingDate, false).then((result) => {
                                        const bookingDateString = formatDate(parsedBookingDate, "yyyy-MM-dd")
                    const bookingSession = formatDate(parsedBookingDate, "HH:mm:ss")
                    const dateItem = result.find((item) => item.date === bookingDateString)

                    if (dateItem) {
                        const sessionItem = dateItem.sessions.find((item) => item.session === 'day_wide' || item.session === bookingSession)

                        if (sessionItem && sessionItem.available >= booking.qty) {
                            setData({
                                product_id: productId,
                                booking_date: bookingSession !== sessionItem.session ? bookingDateString + " 00:00" : null,
                                experience_id: experienceId
                            })
                            setDateState({ ready: true, availability_request_end: true, match: true, selected: false })

                            return
                        }
                    }

                    setDateState({ ready: true, availability_request_end: true, match: false, selected: false })
                })
            }

            setData({
                product_id: productId,
                booking_date: null,
                experience_id: experienceId
            })
        }
    }

    const handleOnDateSelection = (value) => {
        const session = value.session.session === 'day_wide' ? "00:00:00" : value.session.session
        const date = formatDate(parseISO(formatDate(value.date, "yyyy-MM-dd") + " " + session), "yyyy-MM-dd HH:mm:ss")

        if (data.booking_date !== date) {
            setData({
                ...data,
                booking_date: date
            })
            setDateState({ ...dateState, match: true, selected: true })
        }
    }

    const handleOnConfirm = () => {
        handleClose()

        onConfirm({
            data: {
                product_id: data.product_id,
                booking_date: data.booking_date,
                experience_id: data.experience_id

            }
        })
    }

    return (
        <Stack spacing={2} sx={{ marginTop: 1 }}>
            <ProductSelector
                categoriesFetcher={categoriesFetcher}
                experiencesFetcher={experiencesFetcher}
                productsFetcher={productsFetcher}
                selected={{
                    experience: { id: booking.product.experience.id },
                    product: { id: null },
                    state: ''
                }}
                onSelection={handleOnProductSelection}
            />
            {!dateState.availability_request_end && <ObjectStatus size="small" variant="info">
                {t("bookings.actions.product_change.availability_request_waititng")}
            </ObjectStatus>}
            {dateState.ready && data.product_id !== null && (dateState.selected || !dateState.match) && <DateSelector
                product={data.product_id}
                selected={booking.booking_date}
                minQuantity={booking.qty}
                availabilityFetcher={availabilityFetcher}
                onSelection={handleOnDateSelection}
            />}
            <FormButtons
                hideConfirm={data.product_id === null || !dateState.match}
                t={t}
                onConfirm={handleOnConfirm}
                onClose={handleClose}
            />
        </Stack>
    )
}

export default BaseBookingProductChange