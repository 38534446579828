import { useTranslation } from "react-i18next"
import constants from "../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useParams } from "react-router-dom"
import { createCallbackField } from "../../../components/helpers/fieldHelper"
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { getActions } from './SitePage.actions';
import { getHeaderContent } from './SitePage.header';
import { getTabNavigationItems } from "./SitePage.tabs"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import { getShareActions } from "./SitePage.shareActions"

const SitePage = (props) => {
    const { t } = useTranslation();
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()

    const fetcher = () => apiClient.content.site.getSite(entityId)

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("sites.fields.name"),
                constants.STRING_TYPE,
                (value) => {
                    return t("sites.view.title", { name: value });
                }
            )}
            headerContent={getHeaderContent(t, volcanoAuth.user)}
            actions={getActions(apiClient, t, volcanoAuth.user, { hideIcons: true })}
            shareActions={getShareActions(volcanoAuth.user, apiClient, entityId, t)}
            tabNavigationItems={getTabNavigationItems(volcanoAuth.user, apiClient, t)}
        />
    );
};

export default SitePage;