import { createTabBarItem } from "../../../../components/helpers/fieldHelper";
import ProductContainerReferences from "./components/tabs/ProductContainerReferences";
import ProductRates from "./components/tabs/ProductRates";
import ProductRatesCalendarTable from "./components/tabs/ProductRatesCalendarTable";
import ProductSharedExperiences from "./components/tabs/ProductSharedExperiences";
import _isEmpty from "lodash/isEmpty"

export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("catalog.products.view.navbar.rates"),
        ProductRates
    ),
    createTabBarItem(
        t("catalog.products.view.navbar.rates_calendar"),
        ({ entity }) => <ProductRatesCalendarTable entity={entity} filterField="product_id" hideFilter={true} />,
    ),
    /*createTabBarItem(
        t("catalog.products.view.navbar.shared_experiences"),
        ProductSharedExperiences,
        null,
        (entity) => !_isEmpty(entity.shared_experiences)
    ),*/
    createTabBarItem(
        t("catalog.products.view.navbar.rate_groups"),
        ProductContainerReferences,
    )
    /*createTabBarItem(
        t("catalog.products.view.navbar.purchase.title"),
        ProductPurchase
    ),    
    createTabBarItem(
        t("catalog.products.view.navbar.costs"),
        ProductCompoundProducts
    ),
    createTabBarItem(
        t("catalog.products.view.navbar.remote_provider"),
        ProductIacposRates,
        null,
        (entity) => !_isEmpty(entity.remote_product_provider) && entity.remote_product_provider.alias === "Teide"
    ),*/
    /*
    
    createTabBarItem(
        t("bookings.navbar.history"),
        ({ entity, data }) => (
            <BookingHistory
                data={data.getItems()}
                hideUserColumn={user.isIntermediary()}
            />
        ),
        (booking) => apiClient.booking.getBookingHistory(booking.id, {limit:100})
    ),
    
    */
];
