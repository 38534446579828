import { createTabBarItem } from '../../../../components/helpers/fieldHelper';
import ExperienceProducts from "./components/tabs/ExperienceProducts/ExperienceProducts";
import ExperienceSubcategories from "./components/tabs/ExperienceSubcategories/ExperienceSubcategories";

export const getTabNavigationItems = (t) => [
    createTabBarItem(
        t("catalog.experiences.tabs.products"),
        ExperienceProducts
    ),
    createTabBarItem(
        t("catalog.experiences.tabs.subcategories.title"),
        ExperienceSubcategories
    )
];
