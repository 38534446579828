import _isFunction from "lodash/isFunction"
import _get from "lodash/get"
import { useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import Loading from "../../../components/Display/Loading"
import useSnackBars from "../../../components/Snackbar/snack-bar.context"
import { AsyncTabNavigationProvider } from "../../../components/TabNavigation/context/async-tabnavigation.context"
import { AsyncContextProvider, useAsyncContent } from "../../../context/async.context"
import EntityPage from "../EntityPage/EntityPage"
import { cleanUrlParams, getRouteModule } from "../../../lib/utils"

const AsyncEntityPage = (props) => {
    return (
        <AsyncContextProvider fetcher={props.fetcher} loadComponent={Loading}>
            <AsyncEntityPageContent {...props}>
                {props.children}
            </AsyncEntityPageContent>
        </AsyncContextProvider>
    )
}

const AsyncEntityPageContent = (props) => {
    const { data, error, fetchContent } = useAsyncContent()
    const { addAlert } = useSnackBars()
    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    const Content = props.content || null

    useEffect(() => {
        if (error != null) {
            addAlert(`Error [${error.type}]: ${error.message}`, "error")
        }
    }, [error, addAlert])

    // process actions
    const actions = (props.actions || []).map((action) => {
        if (_get(action, "reloadOnSuccess", false)) {
            return {
                ...action,
                onExecute: (data, progressHandler, onSuccess, onError) => {
                    return action.onExecute(data, progressHandler, onSuccess, onError).then(() => fetchContent())
                }
            }
        }

        return action
    })

    // process share actions
    const shareActions = props.shareActions || []

    // process navbar
    const tabNavigationItems =
        !data || !props.tabNavigationItems
            ? null
            : props.tabNavigationItems
                .filter((item) =>
                    _isFunction(item.condition) ? item.condition(data) : true
                )
                .map((item) => {
                    return {
                        name: item.name,
                        isAsync: _isFunction(item.fetcher),
                        fetcher: _isFunction(item.fetcher) ? item.fetcher : null,
                        content: item.content,
                        props: { entity: data, refresh: fetchContent }
                    }
                })

    const goBackHandler = () => {
        let url = getRouteModule(location.pathname)
        const filter = params.get("q")
        
        if (filter) {
            url += `?q=${filter}`
        }
        
        navigate(url, {replace: true})
        cleanUrlParams(window)
    }

    return (
        <AsyncTabNavigationProvider tabs={tabNavigationItems && tabNavigationItems.length > 0 ? tabNavigationItems : null} data={data}>
            <EntityPage
                entity={data}
                {...{
                    ...props,
                    actions: actions,
                    shareActions: shareActions,
                    tabNavigationItems: tabNavigationItems,
                    goBackHandler: goBackHandler
                }}
            >
                {props.content && <Content
                    entity={props.contentProps && props.contentProps.entityField ? _get(data, props.contentProps.entityField) : data}
                    {...props}
                />}
            </EntityPage>
        </AsyncTabNavigationProvider>
    )
}

export default AsyncEntityPage
