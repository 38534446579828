import constants from "../../../../components/entity/constants";
import { createDataSetFacet, createField } from "../../../../components/helpers/fieldHelper";

export const getHeaderContent = (t) => [
    createDataSetFacet(t("catalog.product_rates_publications.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "created",
            t("common.fields.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "created_user.name",
            t("common.fields.created_user"),
            constants.STRING_TYPE
        ),
    ]),
    createDataSetFacet(t("catalog.product_rates_publications.view.facet_configuration"), [
        createField(
            "valid_from",
            t("catalog.product_rates_publications.fields.valid_from"),
            constants.DATE_TYPE
        ),
        createField(
            "date_from",
            t("catalog.product_rates_publications.fields.date_from"),
            constants.DATE_TYPE
        ),
        createField(
            "date_to",
            t("catalog.product_rates_publications.fields.date_to"),
            constants.DATE_TYPE
        ),
        createField(
            "config.only_modified_products",
            t("catalog.product_rates_publications.fields.only_modified_products"),
            constants.BOOLEAN_TYPE 
        ),
        createField(
            "config.hide_intermediaries",
            t("catalog.product_rates_publications.fields.hide_intermediaries"),
            constants.BOOLEAN_TYPE 
        ),
    ]),    
]