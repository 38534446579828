import { useTranslation } from "react-i18next";
import constants from "../../entity/constants";
import { createCommonStateMapping, createField } from "../../helpers/fieldHelper";
import ArrayDataTable from "../../Datatable/ArrayDataTable";


const NotificationProducts = ({ products, actions }) => {
    const { t } = useTranslation("vbms");

    const columns = [
        createField(
            "id",
            "Id",
            constants.STRING_TYPE
        ),
        createField(
            "sku",
            t("catalog.products.fields.sku"),
            constants.STRING_TYPE
        ),
        createField(
            "name",
            t("catalog.products.fields.name"),
            constants.STRING_TYPE
        ),
        createField(
            "experience_name",
            t("catalog.products.fields.experience"),
            constants.STRING_TYPE
        ),
        createField(
            "state",
            t("catalog.products.fields.state"),
            constants.STRING_TYPE,
            createCommonStateMapping(t)
        ),
    ]

    return (
        <ArrayDataTable
            data={products}
            columns={columns}
            actions={actions}
        />
    );
}

export default NotificationProducts;