import { useTranslation } from "react-i18next"
import constants from "../../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useParams } from "react-router-dom"
import { createField } from "../../../../components/helpers/fieldHelper"
import AsyncEntityPage from "../../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { getActions } from './ExperiencePage.actions'
import { getHeaderContent } from "./ExperiencePage.header"
import { getTabNavigationItems } from "./ExperiencePage.tabs"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"

const ExperiencePage = (props) => {
    const { t } = useTranslation("vbms")
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()

    const fetcher = () => {
        return apiClient.catalog.experience
            .getExperience(entityId)
            .then((experience) => {
                experience.subcategories = experience.subcategories.sort((a, b) => a.name.localeCompare(b.name))
                experience.products = experience.products.map((product) => {
                    if (!product.experience) {
                        product.is_main_experience = true
                    }
                    return product
                })

                return experience
            })
    }

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={
                createField(
                    "name",
                    t("catalog.experiences.fields.name"),
                    constants.STRING_TYPE
                )}
            headerContent={getHeaderContent(t, volcanoAuth.user)}
            actions={getActions(apiClient, t, volcanoAuth.user, { hideIcons: true })}
            tabNavigationItems={getTabNavigationItems(t)}
        />
    );
};

export default ExperiencePage
