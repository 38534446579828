import { Box, Stack } from "@mui/material"
import constants from "../../../components/entity/constants"
import { createBookingStateMapping, createCallbackField, createCurrencyField, createField, createLanguagesMapping, createLinkField, createPaymentMethodMapping } from '../../../components/helpers/fieldHelper'
import userPermissions from '../../../lib/user-permissions'
import DisfrutareLink from "../../../components/Volcano/Disfrutare/DisfrutareLink"
import { trimWithEllipsis } from "../../../lib/utils"
import Text from "../../../components/Display/Text"

export const getTableColumns = (t, user, i18n) => {

    const result = [
        createLinkField(
            createCallbackField(
                "locator",
                t("bookings.fields.locator"),
                constants.TEXT_TYPE,
                (value, entity) => {
                    if (!entity.order.voucher_id) return value
                    return (
                        <Stack gap="0.1em">
                            <Box>{value}</Box>
                            <Box>({entity.order.voucher_id})</Box>
                        </Stack>
                    )
                },
                null,
                null,
                "locator"
            ),
            {
                route: "bookings",
                field: "id",
            }
        ),
        createCallbackField(
            "experience",
            t("bookings.fields.experience"),
            constants.TEXT_TYPE,
            (value) => <DisfrutareLink href={"/admin/experiences/view/" + value.id}>{value.name}</DisfrutareLink>,
            null,
            null,
            "experience_name"
        ),
        createLinkField(
            createField(
                "product.name",
                t("bookings.fields.product"),
                constants.TEXT_TYPE
            ),
            {
                route: "catalog_products",
                field: "product.id",
            },
            false,
            "_blank"
        ),
        createCallbackField(
            "order.customer",
            t("bookings.fields.order.customer.title"),
            constants.STRING_TYPE,
            (value) => {
                const limit = 16   // Limit before apply ellipsis
                const name = `${value.first_name} ${value.last_name}`

                return (
                    <Stack>
                        <Box>{trimWithEllipsis(name, limit)}</Box>
                        <Box>{trimWithEllipsis(value.email, limit)}</Box>
                    </Stack>
                )
            }
        ),
        createCallbackField(
            "notes",
            t("bookings.fields.order.customer.notes"),
            constants.TEXT_TYPE,
            (value) => trimWithEllipsis(value, 16),
            () => !user.isIntermediary()
        ),
        createField(
            "order.created",
            t("bookings.fields.order.created"),
            constants.DATETIME_TYPE,
            null,
            null,
            null,
            "created"
        ),
        createField(
            "booking_date",
            t("bookings.fields.booking_date"),
            constants.DATETIME_TYPE,
            null,
            null,
            null,
            "booking_date"
        ),
        createCallbackField(
            "validation_date",
            t("bookings.fields.validation_date"),
            constants.STRING_TYPE,
            (value, entity) => getNoShowDates(value, entity, t, i18n)
        ),
        createField(
            "state",
            t("bookings.fields.state"),
            constants.STRING_TYPE,
            createBookingStateMapping(t)
        ),
        createField(
            "confirmed",
            t("bookings.fields.confirmed"),
            constants.BOOLEAN_TYPE
        ),
        createField(
            "payment_method",
            t("bookings.fields.order.payment_method"),
            constants.STRING_TYPE,
            createPaymentMethodMapping(t)
        ),
        createField(
            "order.collaborator.name",
            t("bookings.fields.order.intermediary.title"),
            constants.STRING_TYPE,
            null,
            () => !user.isIntermediary()
        ),
        createField(
            "order.language",
            t("common.language"),
            constants.STRING_TYPE,
            createLanguagesMapping(t)
        ),
        createCallbackField(
            "product_rates",
            t("bookings.fields.pax"),
            constants.NUMERIC_TYPE,
            (value) => (value || []).reduce((acc, rate) => acc + rate.qty, 0)
        ),
        createCurrencyField(
            createField(
                "amount.total_amount_pvp",
                t("bookings.fields.total_amount_pvp"),
                constants.CURRENCY_TYPE
            ),
            "order.currency",
            "order.main_currency",
            "order.currency_exchange_rate"
        ),
        createCurrencyField(
            createField(
                "amount.total_amount",
                t("bookings.fields.total_amount_net"),
                constants.CURRENCY_TYPE,
                null,
                () => user.hasPermission(userPermissions.PERM_BOOKINGS_VIEW_NET_PRICE)
            ),
            "order.currency",
            "order.main_currency",
            "order.currency_exchange_rate"
        ),
    ]

    return result
}

const getNoShowDates = (value, entity, t, i18n) => {
    // Config from constants.DATETIME_TYPE field component
    const dateTimeConfig = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    };

    // Parse date with previous config
    const parseDate = (date) => {
        return date ? Intl.DateTimeFormat(i18n.language, dateTimeConfig).format(new Date(date)) : null
    }

    if (entity.no_show) {
        return (
            <Stack gap="0.1em" justifyContent="flex-end" alignItems="flex-end" textAlign="right">
                <Text>{t("bookings.fields.no_show")}</Text>
                <Text variant="caption">({parseDate(entity.no_show)})</Text>
            </Stack>
        );
    }

    return <Stack gap="0.1em" justifyContent="flex-end" alignItems="flex-end" textAlign="right">{parseDate(value)}</Stack>
};