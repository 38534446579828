import { Box, InputAdornment } from "@mui/material"
import { Field } from "formik"
import { TextField } from "formik-mui"
import { useTranslation } from "react-i18next"
import { validate } from "./utils"
import { formatCurrencyCode } from "../../lib/utils"

const NumericFormField = ({ field, value }) => {
    const { t, i18n } = useTranslation("vbms")

    const inputProps = {
        size: "small",
        inputMode: 'numeric',
        pattern: '[0-9]*'
    }

    switch (field.type) {
        case "currency":
            inputProps.endAdornment = <InputAdornment position="end">{formatCurrencyCode(i18n, "EUR")}</InputAdornment>
            break
        case "percentage":
            inputProps.endAdornment = <InputAdornment position="end">%</InputAdornment>
            inputProps.inputMode = 'decimal'
            break
        default:
            break
    }

    return (
        <Box sx={{ maxWidth: field.max_width || 100 }}>
            <Field
                component={TextField}
                name={field.id}
                label={field.label}
                required={field.required}
                validate={(value) => validate(field, t, value)}
                InputProps={inputProps}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Box>
    )
}

export default NumericFormField
