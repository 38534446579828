import constants from '../../../components/entity/constants';
import { createDataSetFacet, createFacetField, createField, createObjectStatusField, createLinkField, createCallbackField } from '../../../components/helpers/fieldHelper';
import _has from "lodash/has"

export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("sites.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "name",
            t("sites.fields.name"),
            constants.STRING_TYPE
        ),
        createLinkField(
            createCallbackField(
                "main_site",
                t("sites.fields.main_site"),
                constants.TEXT_TYPE,
                (mainSite) => mainSite.name,
                (site) => _has(site, "main_site")
            ),
            {
                route: "sites",
                field: "main_site.id",
            }
        ),
        createField(
            "base_url",
            t("sites.fields.base_url"),
            constants.STRING_TYPE
        ),
        createField(
            "created",
            t("sites.fields.created"),
            constants.DATETIME_TYPE
        ),
        createLinkField(
            createField(
                "rate_group.name",
                t("catalog.rate_groups.fields.assoc_reference"),
                constants.TEXT_TYPE
            ),
            {
                route: "rate_groups",
                field: "rate_group.id",
            }
        ),
    ]),
    createFacetField("object_status", {
        size: "large",
        field: createObjectStatusField(
            createField(
                "is_admin",
                t("sites.fields.is_admin"),
                constants.BOOLEAN_TYPE
            ),
            null,
            (value) => {
                switch (value) {
                    case false:
                        return "negative";
                    case true:
                        return "positive";
                    default:
                        return "neutral";
                }
            }
        ),
    })
];