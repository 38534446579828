import { Chip, Grid } from '@mui/material';
import _isFunction from 'lodash/isFunction'

const getLabel = (label, maxChars) => {
    if (maxChars && label.length > maxChars) {
        return label.substring(0, maxChars - 1) + "..."
    }

    return label
}

const ChipList = ({ selection, maxChars, onValueDeleted, containerProps, chipProps}) => {
    containerProps = containerProps || {}
    chipProps = chipProps || {}
    return (
        <Grid container spacing={2} {...containerProps} >
            {selection.map((value) => (
                <Grid item key={value.id}>
                    <Chip
                        key={value.id}
                        label={getLabel(value.label, maxChars)}
                        variant="outlined"
                        onDelete={!value.hideDelete && _isFunction(onValueDeleted) ? () => onValueDeleted(value.id) : null}
                        color="primary"
                        {...chipProps}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

export default ChipList