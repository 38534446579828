import { Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import NumericField from "../../../../components/form/NumericField"
import { formatPrice } from "../../../../lib/utils"
import { useEffect, useState } from "react"
import CustomerTypesSelector from "../../../../components/Volcano/Catalog/CustomerTypesSelector"
import { Add, Delete } from "@mui/icons-material"
import { useCache } from "../../../../context/cache.context"
import { processPaxRestrictionValue } from "../product-rates-utils"

const DEFAULT_RATE = {
    id: "",
    customer_type_ids: null,
    retail_price: 0,
    active: false,
    qty_restrictions: {
        min: 0,
        max: null
    }
}

/**
 * Check if a value is a valid number.
 *  
 * @param {*} value 
 * @returns 
 */
const isValidNumber = (value) => {
    return /^-?\d+(\.\d+)?$/.test(value)
}

/**
 * Check if rates net price and commission rate are valid values
 * @param {*} rates 
 */
const validateRates = (rates, withNetPrice, withCustomerTypes) => {
    return rates.every(rate => {
        // check every rate has a valid customer type and there aren't duplicated customer types
        if (withCustomerTypes &&
            (rate.customer_type_ids === "" || rates.some(rateTmp => rateTmp.customer_type_ids === rate.customer_type_ids && rateTmp.id !== rate.id))
        ) {
            return false
        }

        // check qty restrictions are null or valid numbers
        if ((rate.qty_restrictions.min !== null && !isValidNumber(rate.qty_restrictions.min)) ||
            (rate.qty_restrictions.max !== null && !isValidNumber(rate.qty_restrictions.max))) {
            return false
        }

        // check min or max are not negative
        if ((rate.qty_restrictions.min !== null && rate.qty_restrictions.min < -1) ||
            (rate.qty_restrictions.max !== null && rate.qty_restrictions.max < -1)) {
            return false
        } 

        // check min is less than max
        if (rate.qty_restrictions.min !== null && rate.qty_restrictions.max !== null && 
            rate.qty_restrictions.max > -1 && // -1 is a special value that means no limit
            rate.qty_restrictions.min > rate.qty_restrictions.max ) {
            return false
        }


        // check if net price and commission rate are valid numbers 
        if (withNetPrice) {
            return isValidNumber(rate.net_price) && isValidNumber(rate.commission_rate) &&
                rate.net_price >= 0 && rate.commission_rate >= 0 &&
                rate.net_price <= rate.retail_price
        }

        return isValidNumber(rate.retail_price) && rate.retail_price >= 0
    })
}

const valueHasChanged = (value, rate, withNetPrice) => {
    // rates definition has changed 
    if (value.customer_type_ids !== rate.customer_type_ids) {
        return true
    }

    // rates min or max pax has changed
    if (value.qty_restrictions.min !== rate.qty_restrictions.min || value.qty_restrictions.max !== rate.qty_restrictions.max) {
        return true
    }

    if (withNetPrice) {
        return value !== rate && (value.active !== rate.active || value.net_price !== rate.net_price || value.commission_rate !== rate.commission_rate)
    }

    return value !== rate && (value.active !== rate.active || value.retail_price !== rate.retail_price)
}

export const processPaxRestrictionEditValue = (value) => {
    if (value === null || value === undefined || value === -1) {
        return ""
    }

    return value
}

const AddRateButton = ({ onClick }) => {
    const { i18n } = useTranslation("vbms")

    return (
        <TableRow key="add-rate">
            <TableCell align="right" colSpan={5} sx={{ paddingTop: 2, border: "none" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClick}
                    startIcon={<Add />}
                >
                    {i18n.t("catalog.product_rates.add")}
                </Button>
            </TableCell>
        </TableRow>
    )
}

const RateRowComponent = ({ rate, withNetPrice, editCustomerTypes, onChange, onDelete }) => {
    const { i18n } = useTranslation("vbms")
    const [value, setValue] = useState(rate)
    const [customerTypeGroups, setCustomerTypeGroups] = useState(null)
    const cache = useCache()

    useEffect(() => {
        if (editCustomerTypes && customerTypeGroups === null) {
            cache.get("customer_type_groups", []).then((items) => setCustomerTypeGroups(items))
        }
    }, [customerTypeGroups, cache])

    useEffect(() => {
        if (!value) {
            return
        }

        // check value has changed
        if (valueHasChanged(value, rate, withNetPrice)) {
            onChange(value)
        }
    }, [value, rate, withNetPrice, onChange])

    const onChangeHandler = (field, value, processedValue, isValid) => {
        if (rate[field] !== value) {
            if (field === 'customer_type_ids') {
                setValue((prev) => ({ ...prev, [field]: value.join(",") }))
            } else if (field === 'active') {
                setValue((prev) => ({ ...prev, [field]: value }))
            } else if (field === 'min_pax' || field === 'max_pax') {
                setValue((prev) => {
                    const fieldKey = field === 'min_pax' ? 'min' : 'max'      
                    const newQtyRestrictions = { ...prev.qty_restrictions, [fieldKey]: processedValue === "" ? null : processedValue }
                    return { ...prev, qty_restrictions: newQtyRestrictions }
                })
            } else {
                // update retail_price, net_price or commission_rate only if the value is different
                if (rate[field] === value) {
                    return
                }

                setValue((prev) => {
                    const newRateValue = { ...prev, [field]: processedValue }
                    if (!isValid) {
                        if (field === 'retail_price') {
                            newRateValue.commission_rate = null
                            newRateValue.net_price = null
                        } else if (field === 'net_price') {
                            newRateValue.commission_rate = null
                        } else if (field === 'commission_rate') {
                            newRateValue.net_price = null
                        }
                        return newRateValue
                    }

                    if (field === 'retail_price') {
                        newRateValue.commission_rate = null
                        newRateValue.net_price = null
                    } else if (field === 'net_price') {
                        const newCommissionRate = ((newRateValue.retail_price - newRateValue.net_price) / newRateValue.retail_price) * 100
                        // only update commission_rate if it is different
                        if (!isValidNumber(newRateValue.commission_rate)) {
                            newRateValue.commission_rate = 0
                        }
                        if (newRateValue.commission_rate.toFixed(4) !== newCommissionRate.toFixed(4)) {
                            newRateValue.commission_rate = newCommissionRate
                        }
                    } else if (field === 'commission_rate') {
                        const newNetPrice = newRateValue.retail_price * (1 - newRateValue.commission_rate / 100)
                        // only update net_price if it is different
                        if (!isValidNumber(newRateValue.net_price)) {
                            newRateValue.net_price = 0
                        }
                        if (newRateValue.net_price.toFixed(4) !== newNetPrice.toFixed(4)) {
                            newRateValue.net_price = newNetPrice
                        }
                    }

                    return newRateValue
                })
            }
        }
    }

    if (editCustomerTypes && !customerTypeGroups) {
        return null
    }

    return (
        <TableRow key={rate.id}>
            {!editCustomerTypes && <TableCell>{rate.name}</TableCell>}
            {editCustomerTypes && (
                <TableCell>
                    <CustomerTypesSelector
                        customerTypeGroups={customerTypeGroups}
                        value={value.customer_type_ids ? value.customer_type_ids.split(",") : []}
                        onChange={(value) => onChangeHandler("customer_type_ids", value, value, true)}
                    />
                </TableCell>
            )}
            {!withNetPrice && (
                <>
                    <TableCell align="right">
                        <NumericField
                            id="min_pax"
                            value={processPaxRestrictionEditValue(rate?.qty_restrictions?.min)}
                            onChange={(value, processedValue, isValid) => onChangeHandler("min_pax", value, processedValue, isValid)}
                            variant="outlined"
                            size="small"
                            min={0}
                            sx={{ width: '6ch' }}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <NumericField
                            id="max_pax"
                            value={processPaxRestrictionEditValue(rate?.qty_restrictions?.max)}
                            onChange={(value, processedValue, isValid) => onChangeHandler("max_pax", value, processedValue, isValid)}
                            variant="outlined"
                            size="small"
                            min={0}
                            sx={{ width: '6ch' }}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <NumericField
                            id="retail_price"
                            value={rate.retail_price}
                            onChange={(value, processedValue, isValid) => onChangeHandler("retail_price", value, processedValue, isValid)}
                            currency="EUR"
                            variant="outlined"
                            size="small"
                            type="currency"
                            min={0}
                            sx={{ width: '12ch' }}
                        />
                    </TableCell>
                </>
            )}
            {withNetPrice && (
                <>
                    <TableCell align="right">{processPaxRestrictionValue(rate?.qty_restrictions?.min)}</TableCell>
                    <TableCell align="right">{processPaxRestrictionValue(rate?.qty_restrictions?.max)}</TableCell>
                    <TableCell align="right">{formatPrice(i18n, "EUR", rate.retail_price, "EUR", 1)}</TableCell>
                    <TableCell align="right">
                        <NumericField
                            id="net_price"
                            value={rate.net_price}
                            onChange={(value, processedValue, isValid) => onChangeHandler("net_price", value, processedValue, isValid)}
                            currency="EUR"
                            variant="outlined"
                            size="small"
                            type="currency"
                            min={0}
                            max={rate.retail_price}
                            sx={{ width: '12ch' }}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <NumericField
                            value={rate.commission_rate}
                            onChange={(value, processedValue, isValid) => onChangeHandler("commission_rate", value, processedValue, isValid)}
                            variant="outlined"
                            size="small"
                            type="percentage"
                            min={0}
                            max={100}
                            sx={{ width: '10ch' }}
                        />
                    </TableCell>
                </>
            )}
            <TableCell>
                <Checkbox
                    checked={rate.active}
                    onChange={(e) => onChangeHandler("active", e.target.checked, e.target.checked, true)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                {editCustomerTypes && (
                    <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() => onDelete(rate.id)}
                    >
                        <Delete />
                    </IconButton>
                )}
            </TableCell>
        </TableRow>
    )
}

const RatesContainerEditor = ({ rates, onChange, withNetPrice, editCustomerTypes }) => {
    const { t } = useTranslation("vbms")

    const onChangeHandler = (newRate) => {
        const updatedRates = rates.map(rate => (rate.id === newRate.id) ? newRate : rate)
        onChange(updatedRates, validateRates(updatedRates, withNetPrice, editCustomerTypes))
    }

    const onRateAddHandler = () => {
        const updatedRates = [...rates, { ...DEFAULT_RATE, id: -(Math.floor(Math.random() * 10000)) }]
        onChange(updatedRates, validateRates(updatedRates, withNetPrice, editCustomerTypes))
    }

    const onDeleteRateHandler = (rateId) => {
        const updatedRates = rates.filter(rate => rate.id !== rateId)
        onChange(updatedRates, validateRates(updatedRates, withNetPrice, editCustomerTypes))
    }

    return (
        <TableContainer component={Box}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("catalog.rates_table.rates.name")}</TableCell>
                        <TableCell align="right">{t("catalog.rates_table.rates.config.min_pax_short")}</TableCell>
                        <TableCell align="right">{t("catalog.rates_table.rates.config.max_pax_short")}</TableCell>
                        <TableCell align="right">{t("catalog.rates_table.rates.retail_price")}</TableCell>
                        {withNetPrice && (
                            <>
                                <TableCell align="right">{t("catalog.rates_table.rates.net_price")}</TableCell>
                                <TableCell align="right">{t("catalog.rates_table.rates.commission_rate")}</TableCell>
                            </>
                        )}
                        <TableCell>{t("catalog.rates_table.rates.active")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rates.map(rate => (
                        <RateRowComponent
                            key={rate.id}
                            rate={rate}
                            onChange={onChangeHandler}
                            onDelete={onDeleteRateHandler}
                            withNetPrice={withNetPrice}
                            editCustomerTypes={editCustomerTypes}
                        />
                    ))}                    
                </TableBody>
                <TableFooter>
                    {editCustomerTypes && (<AddRateButton onClick={onRateAddHandler} />)}
                </TableFooter>
            </Table>
        </TableContainer>
    )
}

export default RatesContainerEditor