import constants from "../../../components/entity/constants";
import { createAsyncSelectFormField, createField, createFormFieldOption, createIntermediaryStateMapping } from "../../../components/helpers/fieldHelper";
import { crmSchemaKeyItemsFetcher } from '../../../lib/form-options-fetchers'
import userPermissions from "../../../lib/user-permissions";

export const getTableFilter = (apiClient, t, user, cache) => ([
    createField(
        "name",
        t("intermediaries.fields.name"),
        constants.TEXT_TYPE
    ),
    createAsyncSelectFormField(
        "payment_methods",
        t("intermediaries.fields.payment_methods"),
        () => crmSchemaKeyItemsFetcher("payment_methods", cache, apiClient, t, true),
        false
    ),
    createAsyncSelectFormField(
        "rate_group_id",
        t("intermediaries.fields.parent_rates_collaborator"),
        () => crmSchemaKeyItemsFetcher("parent_rates_collaborator", cache, apiClient, t, true),
        false
    ),
    createAsyncSelectFormField(
        "state",
        t("intermediaries.fields.state"),
        () => crmSchemaKeyItemsFetcher("state", cache, apiClient, t, true, false, createIntermediaryStateMapping(t)),
        false
    ),
    createField(
        "created_date_from",
        t("intermediaries.fields.created_from"),
        constants.DATE_TYPE
    ),
    createAsyncSelectFormField(
        "salesman_id",
        t("intermediaries.fields.crm_salesman"),
        () => {
            const options = [createFormFieldOption("", t("table.all"))]
            return cache.get("crm_salesmen", []).then((items) => {
                return options.concat(items.map((item) => ({ value: item.id, label: item.name })))
            })
        },
        false,
        () => user.hasPermission(userPermissions.PERM_CRM_SALESMAN_VIEW)
    ),
    createField(
        "vat_number",
        t("common.fields.vat_type.nif"),
        constants.TEXT_TYPE
    ),
    createAsyncSelectFormField(
        "billing_type",
        t("intermediaries.fields.billing_type"),
        () => crmSchemaKeyItemsFetcher("billing_type", cache, apiClient, t, true),
        false
    ),
    createAsyncSelectFormField(
        "type",
        t("intermediaries.fields.intermediary_type"),
        () => crmSchemaKeyItemsFetcher("intermediary_type", cache, apiClient, t, true),
        false
    ),
    createAsyncSelectFormField(
        "seller_type",
        t("intermediaries.fields.seller_type"),
        () => crmSchemaKeyItemsFetcher("seller_type", cache, apiClient, t, true),
        false
    ),
    createAsyncSelectFormField(
        "location_type",
        t("intermediaries.fields.location_type"),
        () => crmSchemaKeyItemsFetcher("location_type", cache, apiClient, t, true),
        false
    ),
])