import { getActions as getRowActions } from "../ProductPage/ProductPage.actions"
import userPermissions from "../../../../lib/user-permissions"
import ProductAddForm from "../ProductPage/components/actions/ProductAddForm"
import availableRoutes from "../../../../routes/availableRoutes"

export const getActions = (apiClient, t, navigate, user) => [
    {
        id: "product_add",
        title: t("common.create"),
        withDialog: {
            title: t("catalog.products.actions.add.modal_title"),
            content: ProductAddForm,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.catalog.product.addProduct(result)
                .then((result) => {
                    onSuccess(
                        t("catalog.products.actions.add.confirm")
                    )
                    navigate(availableRoutes.catalog_products_view.path.replace(":entityId", result.id))
                })
                .catch((error) => {
                    onError(
                        t("catalog.products.actions.error")
                    )

                    throw error
                })
        },
        condition: () => user.hasPermission(userPermissions.PERM_CATALOG_ADMINISTER)

    },
]
export const getTableActions = (...props) => getRowActions(...props)
