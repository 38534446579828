import { useTranslation } from "react-i18next"
import { createField } from "../../../../../../components/helpers/fieldHelper"
import constants from "../../../../../../components/entity/constants"
import ArrayDataTable from "../../../../../../components/Datatable/ArrayDataTable"
import { Download, Visibility } from "@mui/icons-material"
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"
import ProductContainerReferenceProductRates from "../actions/ProductContainerReferenceProductRates"
import { Box } from "@mui/material"
import { downloadFile, getSuggestedFilename } from "../../../../../../lib/utils"
import { useEffect, useState } from "react"
import { useAsyncContent } from "../../../../../../context/async.context"

const ProductContainerReferenceProductRatesWrapper = ({ selection, containerFetcher, filterParams, hideFilter }) => {
    const { apiClient } = useVolcanoApiClient()
    const [container, setContainer] = useState(null)

    const fetchExperiences = () => apiClient.catalog.productRatesPublication
        .getContainerExperiences(selection.product_rates_publication.id, selection.id)
        .then((experiences) => {
            setContainer(apiClient.getEntityInstance(null, {
                ...selection,
                sorted_experiences: experiences,
            }))
        })

    const refreshContainer = () => {
        containerFetcher().then((container) => {
            fetchExperiences()
        })
    }        

    useEffect(() => {
        if (!selection || container) {
            return
        }

        fetchExperiences()
    }, [container])


    if (!container) {
        return null
    }

    return <Box pt={2}><ProductContainerReferenceProductRates selection={container} containerFetcher={refreshContainer} filterParams={filterParams} hideFilter={hideFilter} /></Box>
}

const ProductContainerReferencesTable = ({ entity, containerType }) => {
    const { fetchContent } = useAsyncContent()
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()

    if (!entity || !entity.product_container_references) {
        return null;
    }

    const containers = entity.product_container_references
        .filter((container) => container.container_type === containerType)
        .map((container) =>
            apiClient.getEntityInstance(null, {
                ...container,
                product_rates_publication: {
                    id: entity.id,
                    description: entity.description,
                },
            }))

    return (
        <ArrayDataTable
            data={containers}
            columns={[
                createField(
                    "name",
                    t("common.fields.name"),
                    constants.STRING_TYPE
                ),
            ]}
            actions={[
                {
                    id: "view",
                    title: t("common.open"),
                    scope: "row",
                    icon: Visibility,
                    withDialog: {
                        title: (container) => t("catalog.product_rates_publications.actions.product_container_reference.view_products.modal_title", { name: container.name }),
                        content: (props) => <ProductContainerReferenceProductRatesWrapper {...props} containerFetcher={fetchContent} />,
                        maxWidth: "lg",
                        closeIcon: true,
                    },
                    onExecute: (container, progressHandler, onSuccess, onError) => { },
                },
                {
                    id: "download_pdf",
                    title: t("common.download"),
                    scope: "row",
                    icon: Download,
                    onExecute: (container, progressHandler, onSuccess, onError) => {
                        const fileName = getSuggestedFilename(entity.description + " - " + container.name) + ".pdf"
                        apiClient.catalog.productRatesPublication.getProductRatesPublicationPdf(
                            entity.id,
                            container.id,
                            container.container_type
                        )
                            .then(pdf => {
                                downloadFile(pdf, fileName)
                            })
                    },
                },
            ]}
        />
    );
}

export default ProductContainerReferencesTable;