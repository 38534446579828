import { BrowserRouter, Route, Routes } from "react-router-dom"
import { useVolcanoAuth } from "../context/VolcanoAuthContext"
import MainLayout from "../layout/MainLayout/MainLayout"
import LoginPage from "../pages/LoginPage"
import SuggestionAddPage from "../pages/Suggestions/SuggestionAddPage/SuggestionAddPage"
import availableRoutes from "./availableRoutes"
import ProtectedRoute from "./ProtectedRoute"
import AuthVerify from "./AuthVerify"
import ArticleContentPage from "../pages/ArticleContentPage/ArticleContentPage"
import _get from "lodash/get"
import NotFound from "../pages/NotFound"
import { useVolcanoApiClient } from "../context/VolcanoApiClientProvider"
import { DialogProvider } from "../components/Dialog/dialog.context"
import PaymentTransactionCustomerResultPage from "../pages/PaymentTransactions/PaymentTransactionCustomerResultPage"
import PublicLayout from "../layout/PublicLayout/PublicLayout"
import PublicLayoutWithLogo from "../layout/PublicLayout/PublicLayoutWithLogo"
import PublicRoute from "./PublicRoute"
import ManualContentPage from "../pages/Manuals/ManualsContentPage"

const AppRoutes = () => {
    const { siteConfig } = useVolcanoApiClient()
    const authContext = useVolcanoAuth()

    return (
        <BrowserRouter>
            <DialogProvider>
                <Routes>
                    <Route element={<PublicRoute component={PublicLayoutWithLogo} />}>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/reset-password/:hash" element={<LoginPage />} />
                    </Route>
                    <Route element={<PublicRoute component={PublicLayout} />}>
                        <Route path="/customers/payment/result/" element={<PaymentTransactionCustomerResultPage />} />
                    </Route>
                    <Route element={<PublicRoute component={PublicLayout} isAllowed={siteConfig.show_suggestion_link} />}>
                        <Route path="suggestion-add" element={<SuggestionAddPage />} />
                    </Route>
                    <Route element={<ProtectedRoute isAllowed={authContext.isAuthenticated} component={MainLayout} />}>
                        {Object.keys(availableRoutes)
                            .filter(key => {
                                const routePermissions = availableRoutes[key].permissions
                                const userPermissions = authContext.user ? Object.keys(authContext.user.permissions) : []

                                return routePermissions.length === 0 || routePermissions.filter(x => userPermissions.includes(x)).length > 0
                            })
                            .map(key => {
                                const Element = availableRoutes[key].element
                                return (
                                    <Route
                                        key={key}
                                        path={availableRoutes[key].path}
                                        element={Element && <Element />} />
                                )
                            })}
                        {authContext.isAuthenticated && (
                            <>
                                <Route
                                    path="/terms-and-conditions"
                                    element={<ArticleContentPage
                                        siteId={_get(siteConfig, "_links.terms-conditions.data.site_id")}
                                        sectionId={_get(siteConfig, "_links.terms-conditions.data.section_id")}
                                        articleId={_get(siteConfig, "_links.terms-conditions.data.article_id")}
                                    />}
                                />
                                {authContext.user.hasPermission("manuals-access") && <Route
                                    path="/manuals"
                                    element={<ManualContentPage />}
                                />}
                            </>
                        )}
                        <Route path="/" element={<></>} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </DialogProvider>
            <AuthVerify />
        </BrowserRouter >
    )
}

export default AppRoutes
