import { createExportField, createCommonVisibilityMapping, createProductStateMapping } from "../../../../components/helpers/fieldHelper";

export const getTableExport = (apiClient, t) => {
    return (filter, progressHandler) => {
        apiClient.catalog.product.exportProducts(
            [
                createExportField(
                    "id",
                    "id",
                ),
                createExportField(
                    "name",
                    t("catalog.products.fields.name")
                ),
                createExportField(
                    "experience.name",
                    t("catalog.products.fields.experience")
                ),
                createExportField(
                    "shared_experiences",
                    t("catalog.products.fields.shared_experiences"),
                    null,
                    (entity) => entity.shared_experiences.map(item => item.name).join(", ")
                ),
                createExportField(
                    "state",
                    t("catalog.products.fields.state"),
                    createProductStateMapping(t)
                ),
                createExportField(
                    "visibility",
                    t("common.visibility.title"),
                    createCommonVisibilityMapping(t)
                )
            ],
            filter,
            progressHandler
        )
    }
} 
