import constants from '../../../../components/entity/constants';
import {
    createDataSetFacet,
    createField,
    createCommonStateMapping, createCallbackField,
    createFacetField,
    createObjectStatusField,
    createFacetsContainer,
    createExperienceSortTypeMapping
} from '../../../../components/helpers/fieldHelper';
import isEmpty from "lodash/isEmpty"

export const getHeaderContent = (t) => [
    createDataSetFacet(t("catalog.experiences.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE)
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createField(
                    "state",
                    t("common.states.active"),
                    constants.STRING_TYPE,
                    createCommonStateMapping(t)
                ),
                null,
                (value) => {
                    switch (value) {
                        case "active":
                            return "positive"
                        case "inactive":
                            return "negative"
                    }
                }
            )
        })
    ]),
    createDataSetFacet(t("catalog.experiences.facet_information_config"), [
        createField(
            "main_subcategory.name",
            t("catalog.experiences.fields.category"),
            constants.STRING_TYPE
        ),
        createCallbackField(
            "languages",
            t("catalog.experiences.fields.languages"),
            constants.STRING_TYPE,
            (values) => values.join(", ").toUpperCase(),
            (entity) => !isEmpty(entity.languages)
        ),
        createField(
            "sort_type",
            t("catalog.experiences.fields.sort_type"),
            constants.STRING_TYPE,
            createExperienceSortTypeMapping(t)
        ),
    ]),
];
