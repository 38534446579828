import { styled, Typography } from "@mui/material";
import Title from "./Title";

const Text = (props) => {

  return (
    <>
      {props.title && (<Title level="h5">{props.title}</Title>)}
      <Typography component="span" variant={props.variant || "body1"} sx={{ ...props.sx, display: "block", color: "text.primary"}}>
        {props.children}
      </Typography>
    </>
  );
};

export default styled(Text)``;
