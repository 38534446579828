import { Add } from "@mui/icons-material"
import { Box, Chip, FormControl, IconButton, InputLabel, ListSubheader, Menu, MenuItem, OutlinedInput, Select, Stack, useTheme } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const findCustomerType = (customerTypeGroups, id) => {
    // add group id to each customer type and return the customer type that matches the id
    id = parseInt(id)
    for (const group of customerTypeGroups) {
        const customerType = group.customer_types.find((customerType) => customerType.id === id)
        if (customerType) {
            return { ...customerType, id: customerType.id + "", group_id: group.id }
        }
    }

    return null
}

/**
 * Sort customer types by group.
 * Group precedence: 5, 6, 13, other groups in ascending order.
 * @param {*} selection 
 */
const sortCustomerTypes = (selection) => {
    return selection.sort((a, b) => {
        var valueA = a.id
        switch (a.group_id) {
            case 5:
                valueA += 0
                break
            case 6:
                valueA += 1000
                break
            case 13:
                valueA += 2000
                break                
            default:
                valueA += 10000
                break;
        }

        var valueB = b.id
        switch (b.group_id) {
            case 5:
                valueB += 0
                break
            case 6:
                valueB += 1000
                break
            case 13:
                valueB += 2000
                break                
            default:
                valueB += 10000
                break;
        }

        return valueA - valueB
    })
}

/**
 * Sort customer type groups.
 * Group precedence: 5, 6, 13, other groups in ascending order.
 * @param {*} selection 
 */
const sortCustomerTypeGroups = (groups) => {
    return groups.sort((a, b) => {
        var valueA = a.id
        switch (valueA) {
            case 5:
                valueA += 0
                break
            case 6:
                valueA += 1000
                break
            case 13:
                valueA += 2000
                break                
            default:
                valueA += 10000
                break;
        }

        var valueB = b.id
        switch (valueB) {
            case 5:
                valueB += 0
                break
            case 6:
                valueB += 1000
                break
            case 13:
                valueB += 2000
                break                
            default:
                valueB += 10000
                break;
        }

        return valueA - valueB
    })
}

const getStyles = (value, selection, theme) => {
    return {
        fontWeight: selection.some((selected) => selected.id === value)
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
    };
}

/**
 * Deletable chip for selected option.
 */
const SelectedOptionChip = ({ option, onDelete }) => {
    return (
        <Chip
            label={option.name}
            onDelete={() => onDelete(option.id)}
        />
    )
}


const SelectGroupOptions = ({ groupId, groupName, options, selection, handleClose, onClick }) => {
    const theme = useTheme()

    if (!options || options.length === 0) {
        return null
    }

    // if an element of the group is selected, hide the group
    if (selection.some((selected) => selected.group_id === groupId)) {
        return null
    }

    return (
        <>
            <ListSubheader
                sx={{
                    fontSize: theme.typography.fontSize,
                    fontWeight: theme.typography.fontWeightBold,
                    backgroundColor: theme.palette.grey.A100
                }}
            >
                {groupName}
            </ListSubheader>
            {options.map((option, index) => (
                <MenuItem
                    key={option.id + ""}
                    value={option.id + ""}
                    selected={selection.some((selected) => selected.id === option.id)}
                    sx={getStyles(option.id + "", selection, theme)}
                    onClick={() => { handleClose(); onClick(option.id) }}
                >
                    {option.name}
                </MenuItem>
            ))}
        </>
    )
}

const AddButtonSelect = ({ selection, options, onAdd }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (<Box>
        <IconButton
            aria-label="add"
            color="primary"
            sx={{ padding: 0 }}
            onClick={handleClick}
        >
            <Add />
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {options.map((group, index) => (
                <SelectGroupOptions
                    key={index}
                    groupId={group.id}
                    groupName={group.name}
                    options={group.customer_types}
                    selection={selection}
                    handleClose={handleClose}
                    onClick={onAdd}
                />
            ))}
        </Menu>
    </Box>)
}

/**
 * CustomerTypesSelector component.
 *
 * @param {Object[]} customerTypeGroups array of customer type groups.
 * @param {array} value the selected customer types.
 * @param {function} onChange callback function to handle selection change.
 */
const CustomerTypesSelector = ({ customerTypeGroups, value, onChange }) => {
    const { t } = useTranslation("vbms")

    value = value || []

    const handleOnAdd = (selectedId) => {
        onChange([...value, selectedId].sort((a, b) => a - b))
    }

    const handleDelete = (id) => {
        onChange(value.filter((value) => value !== id))
    }

    // order customerTypeGroups (5, 6, other groups)
    customerTypeGroups = sortCustomerTypeGroups(customerTypeGroups)

    const customerTypesSelected = sortCustomerTypes(value.map((value) => findCustomerType(customerTypeGroups, value)))

    const fieldId = "customer-types"

    return (
        <Box>
            <Stack
                direction="row"
                spacing={1}
                overflow="hidden"
                alignItems="center"
            >
                {customerTypesSelected.map((customerType) => {
                    return <SelectedOptionChip key={customerType.id} option={customerType} onDelete={handleDelete} />
                })}
                <AddButtonSelect selection={customerTypesSelected} options={customerTypeGroups} onAdd={handleOnAdd} />
            </Stack>
        </Box>
    )
}

export default CustomerTypesSelector