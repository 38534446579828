import { Collapse, IconButton, TableBody, TableCell, TableRow, styled } from "@mui/material"
import _isFunction from "lodash/isFunction"
import _unset from "lodash/unset"
import EntityField from "../../entity/EntityField"
import ObjectStatusFacet from "../../entity/ObjectStatusFacet"
import DataTableCell from "./DataTableCell"
import { useState } from "react"
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from "@mui/icons-material"
import ArrayDataTable from "../ArrayDataTable"

/**
 * Returns a new array with the elements grouped by the given field. 
 * If the field is not present in the element, the element is ignored.
 * If the field is present but its value is null or empty string, the element is ignored. 
 * If the field is present and its value is an object then the object id is used for grouping, keeping the object
 * as value.
 * If the field is present and its value is a string or a number then the value is used for grouping.
 * 
 * @param {string} groupByField the field name to use for grouping 
 * @param {function} groupBySort the sort function to use for grouping
 * @param {Object[]} items the items to group
 */
const groupItems = (groupByField, groupBySort, items) => {

    if (!items || items.length === 0) {
        return []
    }

    const grouped = items.reduce((groups, item) => {
        if (groupByField in item) {
            let value = item[groupByField]
            let entity = null

            if (value instanceof Object) {
                // if the value is a an object then use its id field as grouping value
                entity = value
                value = entity?.id ?? null
            } else {
                entity = { id: value, name: value }
            }

            if (value !== null && value !== "") {
                if (value in groups) {
                    groups[value].entity.items.push(item)
                } else {
                    groups[value] = {
                        id: value,
                        entity: entity,
                        label: entity?.name ?? value,
                    }

                    groups[value].entity.items = [item]
                }
            }
        }

        return groups
    }, {})

    // convert the object to an array and sort it 
    return Object.values(grouped).sort(groupBySort ?? ((a, b) => a.label.localeCompare(b.label)))
}

const processContent = (field, entity) => {
    switch (field.facet) {
        case "object_status":
            _unset(field.options.field, "label")
            return (
                <ObjectStatusFacet
                    size={field.options.size}
                    field={field.options.field}
                    variant={field.options.field.variant}
                    entity={entity}
                />
            );
        default:
            return <EntityField field={field} entity={entity} />
    }
}

const GroupedTableRow = ({ groupColumns, columns, row, actions, highlight }) => {
    const [open, setOpen] = useState(true)

    const columnIds = {
        [row.id]: 0
    }

    return (
        <>
            <TableRow
                role="checkbox"
                aria-checked={false}
                tabIndex={-1}
                key={row.id + "-" + columnIds[row.id]++}
                selected={false}
                sx={{ bgcolor: "background.info.main" }}
            >
                <TableCell padding="checkbox">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
                    </IconButton>
                </TableCell>
                {groupColumns.map((column, index) => {
                    if (!(column.id in columnIds)) {
                        columnIds[column.id] = 0;
                    }

                    //todo: custom data table cell with collapse/expand for grouped rows

                    return (
                        <DataTableCell
                            key={column.id + "-" + columnIds[column.id]++}
                            column={column}
                            component={index === 0 ? "td" : "td"}
                            scope="row"
                        >
                            {processContent(column, row.entity)}
                        </DataTableCell>
                    )
                })}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={groupColumns.length + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ArrayDataTable
                            columns={columns}
                            data={row.entity.items}
                            actions={actions}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

const GroupedDataTableBody = (props) => {
    const {
        groupBy,
        columns,
        actions,
        rows,
        highlight,
    } = props

    const groupedRows = groupItems(groupBy.field, groupBy.sort, rows)

    return (
        <TableBody>
            {groupedRows.map((row, index) =>
                <GroupedTableRow
                    key={row.id}
                    groupColumns={groupBy.columns}
                    columns={columns}
                    row={row}
                    actions={actions}
                    highlight={highlight}
                />
            )}
        </TableBody>
    )
}

export default GroupedDataTableBody