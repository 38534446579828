import constants from "../../../../components/entity/constants"
import { createField, createFormField, createSelectFormField, createAsyncSelectFormField, createCommonStateMapping, createOptionsMapping } from "../../../../components/helpers/fieldHelper"
import { getSubcategoriesFilter } from "../../../../lib/collection-filters"
import { buildOptions, sitesFetcher } from "../../../../lib/form-options-fetchers"

export const getTableFilter = (t, apiClient, user, cache) => ([
    createField(
        "ids",
        "ID",
        constants.STRING_TYPE
    ),
    createField(
        "name",
        t("catalog.subcategories.fields.name"),
        constants.TEXT_TYPE
    ),
    getSubcategoriesFilter(apiClient, t, user, true),
    createFormField(
        createSelectFormField(
            "state",
            t("common.visible"),
            createOptionsMapping(t, createCommonStateMapping(t), true),
        ),
        false,
        "active"
    ),
    createFormField(
        createAsyncSelectFormField(
            "site_id",
            t("sites.filter.title"),
            (params) => sitesFetcher(cache, apiClient, params).then((items) => {
                const sites = items.filter((site) => site.enterprise.id === user.corporate_account.enterprise.id)
                return buildOptions(sites, t)
            }),
            true,
            null,
            null,
            false,
            false
        ),
        false,
        []
    ),
])
