import userPermissions from "../../../../lib/user-permissions"
import ProductRatesPublicationForm from "../actions/ProductRatesPublicationForm"

export const getTableActions = (t, apiClient, user) => [
    {
        title: t("catalog.product_rates_publications.add"),
        scope: "page",
        withDialog: {
            title: t("catalog.product_rates_publications.actions.add.modal_title"),
            content: ProductRatesPublicationForm,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            // process boolean fields (convert from string to boolean)
            result.config.only_modified_products = parseInt(result.config.only_modified_products) === 1
            result.config.hide_intermediaries = parseInt(result.config.hide_intermediaries) === 1
            
            progressHandler(-1, t("catalog.product_rates_publications.actions.add.progress"))
            return apiClient.catalog.productRatesPublication.addProductRatePublication(result)
                .then((result) => {
                    onSuccess(
                        t("catalog.product_rates_publications.actions.add.confirm", {
                            name: result.description,
                        })
                    )

                    return result
                })
                .catch((error) => {
                    onError(
                        t("catalog.product_rates_publications.actions.add.error", {
                            name: result.description,
                        })
                    )
                })
        },
        condition: () => user.hasPermission(userPermissions.PERM_CATALOG_ADMINISTER)
    },
]