import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _has from 'lodash/has'
import { CUSTOMER_TYPE_IDS } from '../../BookingsCollection/utils'

export const getRatesNotInBooking = (rates, selection) => {
    if (_isEmpty(rates)) {
        return []
    }

    return rates.filter(
        (rate) => !selection
            .map((selectionItem) => {
                return parseInt(selectionItem.rate_id)
            })
            .includes(parseInt(rate.id))
    )
}

export const getRateName = (rate) => {
    return rate.customer_types
        .sort((ct1, ct2) => ct1.group_id === 5 ? -1 : 1)
        .reduce((names, ct) => {
            names.push(ct.name)
            return names
        }, [])
        .join(", ")
}

export const getRateAgeRestriction = (rate) => {
    const ct = rate.customer_types.find((ct) => ct.group_id === 5)
    return ct.age_restriction || {}
}

export const getRateQtyRestriction = (rate) => {
    const min = rate.qty_restrictions.min
    const max = rate.qty_restrictions.max

    return {
        min: isNaN(min) || min === null ? 0 : min,
        max: isNaN(max) || max === null ? Number.MAX_SAFE_INTEGER : max,
    }
}

export const getMaxAvailableQty = (maxQty, selection) => {
    maxQty = (isNaN(maxQty) || maxQty === -1) ? Number.MAX_SAFE_INTEGER : maxQty
    return maxQty - selection.reduce((acc, rate) => acc + rate.qty, 0)
}

export const isMaxQtyLimit = (rate, qty, maxQtyAttempted) => {
    if (maxQtyAttempted) {
        return true
    }

    if (rate.qty_restrictions.max === -1) {
        return false
    }

    if (rate.qty_restrictions.max && rate.qty_restrictions.max <= qty) {
        return true
    }

    return false
}

export const isMaxQtyAttempted = (max, finalRates) => {
    return getMaxAvailableQty(max, finalRates) === 0
}

export const buildResult = (bookingRates, selection, withParticipants, allowEditAmount) => {
    let qtyChanged = false
    let amountChanged = false
    let participantsChanged = []
    let result
    let finalRates

    // Discard deleted participants and new rates without participants
    if (withParticipants) {
        finalRates = selection.map((finalRate) => {
            finalRate.participants = finalRate.participants
                .filter(
                    (participant) => !_get(participant, 'deleted', false)
                ).map((participant) => {
                    if (_has(participant, 'booking_ticket')) {
                        delete participant.booking_ticket
                    }

                    return participant
                })

            return finalRate
        }).filter((finalRate) => {
            return _has(finalRate, 'id') || finalRate.participants.length > 0
        })
    } else {
        finalRates = selection.map((finalRate) => finalRate)
    }

    if (finalRates.filter((finalRate) => _has(finalRate, 'rate_id')).length > 0) { // has new rates
        qtyChanged = true
    } else { // only booking rates
        finalRates.forEach((finalRate) => {
            const index = bookingRates.findIndex(
                (bookingRate) => bookingRate.id === finalRate.id
            )

            if ((bookingRates[index].qty) === (finalRate.qty)) {
                if (_has(finalRate, 'participants')) {
                    finalRate.participants
                        .filter((participant) => participant.changed)
                        .forEach((participant) => {
                            // set default id card if participant is a child and has no id card
                            // required workaround for the backend
                            if (finalRate.customer_types.includes(CUSTOMER_TYPE_IDS.CHILDREN) && _isEmpty(participant?.id_card)) {
                                participant.id_card = " "
                            }

                            participantsChanged.push(participant)

                            qtyChanged = qtyChanged || !_has(participant, "id")
                        })
                }
            } else {
                qtyChanged = true
            }
        })
    }

    if (allowEditAmount) {
        bookingRates.forEach((bookingRate) => {
            const index = finalRates.findIndex(
                (finalRate) => finalRate.id === bookingRate.id
            )

            amountChanged = amountChanged || ((finalRates[index].unit_price) !== (bookingRate.unit_price))
        })
    } else {
        finalRates.forEach((finalRate) => {
            delete finalRate.unit_price
        })
    }

    result = {
        qty_changed: qtyChanged,
        amount_changed: amountChanged,
        booking_rates: finalRates
    }

    if (withParticipants) {
        if (!qtyChanged) {
            result = {
                ...result,
                participants_changed: participantsChanged
            }
        }

        result.participants_valid = finalRates.filter((finalRate) => {
            return finalRate.participants.filter(
                (participant) => _has(participant, 'valid') && !participant.valid
            ).length > 0
        }).length === 0
    }

    result.amounts_valid = finalRates.filter((finalRate) => {
        return _has(finalRate, "id") && _has(finalRate, "unit_price") && (finalRate.unit_price === "" || isNaN(finalRate.unit_price))
    }).length === 0

    return result
}