import { useTranslation } from "react-i18next"
import { createField } from '../../../../../components/helpers/fieldHelper'
import constants from '../../../../../components/entity/constants'
import { Box, DialogContentText, Stack } from "@mui/material"
import { useVolcanoApiClient } from "../../../../../context/VolcanoApiClientProvider"
import { useDialog } from "../../../../../components/Dialog/dialog.context"
import ValueSelectorTable from "../../../../../components/ValueSelectorDialog/ValueSelectorTable"
import { useState } from "react"
import EntityActionsGroup from "../../../../../components/action/EntityActionsGroup"
import _isFunction from "lodash/isFunction"

const BookingSetIntermediary = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { handleClose } = useDialog()
    const [selected, setSelected] = useState([])
        
    const table =
    {
        rowsPerPage: 10,
        filterConfig: [
                createField(
                    "name",
                    t("intermediaries.fields.name"),
                    constants.STRING_TYPE
                ),
                createField(
                    "vat_number",
                    t("common.fields.vat_type.nif"),
                    constants.STRING_TYPE
                ),
            ],
        columns: [
            createField(
                "name",
                t("intermediaries.fields.name"),
                constants.STRING_TYPE
            ),
            createField(
                "id_card",
                t("common.fields.vat_type.nif"),
                constants.STRING_TYPE
            ),
            createField(
                "rate_group.name",
                t("intermediaries.fields.parent_rates_collaborator"),
                constants.TEXT_TYPE,
            ),
            createField(
                "at.country.id",
                t("common.fields.contact_details.country"),
                constants.STRING_TYPE
            ),            
            createField(
                "enterprise.name",
                t("bookings.fields.order.enterprise.title"),
                constants.STRING_TYPE
            ),
            ],
        fetcher: (params) => {
            return apiClient.collaborator.getCollaborators({
                ...params
            })
        },
    }

    const actions = [
        {
            id: "confirm",
            title: t("common.accept"),
            disabled: selection.length === 0,
            onExecute: () => {
                handleClose()
                return onAction({
                    order_id: selection.order.id,
                    locator: selection.locator,
                    collaborator_id: selected.length > 0 ? selected[0] : null
                })
            },
        },
        {
            id: "cancel",
            title: t("common.cancel"),
            onExecute: () => {
                handleClose()
                if (_isFunction(onCancel)) {
                    return onCancel()
                }
            }
        }
    ]

    return (
        <Stack spacing={2}>
            <DialogContentText>{t("bookings.actions.set_intermediary.modal_content", { "locator": selection.locator, "id": selection.id })}</DialogContentText>
            <ValueSelectorTable
                multipleSelection={false}
                table={table}
                selected={selected.map((item) => item.id)}
                onSelectionChange={setSelected}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <EntityActionsGroup actions={actions} />
            </Box>
        </Stack>
    )
}

export default BookingSetIntermediary