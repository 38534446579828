import userPermissions from "../../../lib/user-permissions"
import ExperiencesCollectionPage from "../Experiences/ExperiencesCollection/ExperiencesCollection"
import ExperiencePage from "../Experiences/ExperiencePage/ExperiencePage"
import ProductRatesPublicationPage from "../ProductRatesPublications/ProductRatesPublicationPage/ProductRatesPublicationPage"
import ProductRatesPublicationsCollectionPage from "../ProductRatesPublications/ProductRatesPublicationsCollection/ProductRatesPublicationsCollectionPage"
import RateGroupPage from "../RateGroups/RateGroupPage/RateGroupPage"
import RateGroupsCollectionPage from "../RateGroups/RateGroupsCollection/RateGroupsCollectionPage"
import SubcategoriesCollectionPage from "../Subcategories/SubcategoriesCollection/SubcategoriesCollectionPage"
import ProductsCollectionPage from "../Products/ProductsCollection/ProductsCollection"
import ProductPage from "../Products/ProductPage/ProductPage"
import ProductFormPage from "../Products/ProductFormPage"


const catalogRoutes = {
    catalog_subcategories: {
        path: "/content/subcategories",
        element: SubcategoriesCollectionPage,
        permissions: [
            userPermissions.PERM_CATALOG_VIEW,
            userPermissions.PERM_CATALOG_ADMINISTER
        ]
    },
    disfrutare_subcategory_view: {
        path: "/admin/subcategories/view/:entityId",
        element: null,
        permissions: [
            userPermissions.DISFRUTARE_ACCESS
        ]
    },
    catalog_experiences: {
        path: "/catalog/experiences",
        element: ExperiencesCollectionPage,
        permissions: [
            userPermissions.PERM_CATALOG_VIEW
        ]
    },
    catalog_experiences_view: {
        path: "/catalog/experiences/:entityId",
        element: ExperiencePage,
        permissions: [
            userPermissions.PERM_CATALOG_VIEW
        ]
    },
    disfrutare_experience_add: {
        path: "/admin/experiences/add",
        element: null,
        permissions: [
            userPermissions.DISFRUTARE_ACCESS
        ]
    },
    disfrutare_experience_edit: {
        path: "/admin/experiences/edit/:entityId",
        element: null,
        permissions: [
            userPermissions.DISFRUTARE_ACCESS
        ]
    },
    disfrutare_experience_view: {
        path: "/admin/experiences/view/:entityId",
        element: null,
        permissions: [
            userPermissions.DISFRUTARE_ACCESS
        ]
    },
    disfrutare_products_add: {
        path: "/admin/products/add",
        element: null,
        permissions: [
            userPermissions.DISFRUTARE_ACCESS
        ]
    },
    disfrutare_product_view: {
        path: "/admin/products/view/:entityId",
        element: null,
        permissions: [
            userPermissions.DISFRUTARE_ACCESS
        ]
    },
    disfrutare_product_edit: {
        path: "/admin/products/edit/:entityId",
        element: null,
        permissions: [
            userPermissions.DISFRUTARE_ACCESS
        ]
    },
    disfrutare_product_translate: {
        path: "/admin/products/translate/:entityId",
        element: null,
        permissions: [
            userPermissions.DISFRUTARE_ACCESS
        ]
    },
    disfrutare_availability_products: {
        path: "/admin/days_products/add/prId:entityId",
        element: null,
        permissions: [
            userPermissions.DISFRUTARE_ACCESS
        ]
    },
    catalog_products: {
        path: "/catalog/products",
        element: ProductsCollectionPage,
        permissions: [
            userPermissions.PERM_CATALOG_VIEW
        ]
    },
    catalog_products_view: {
        path: "/catalog/products/:entityId",
        element: ProductPage,
        permissions: [
            userPermissions.PERM_CATALOG_VIEW
        ]
    },
    catalog_products_edit: {
        path: "/catalog/products/edit/:entityId",
        element: ProductFormPage,
        permissions: [
            userPermissions.PERM_CATALOG_ADMINISTER
        ]
    },
    rate_groups: {
        path: "/catalog/rate-groups",
        element: RateGroupsCollectionPage,
        permissions: [
            userPermissions.PERM_CATALOG_ADMINISTER
        ]
    },
    rate_groups_view: {
        path: "/catalog/rate-groups/:entityId",
        element: RateGroupPage,
        permissions: [
            userPermissions.PERM_CATALOG_ADMINISTER
        ]
    },
    product_rates_publications: {
        path: "/catalog/product-rates-publications",
        element: ProductRatesPublicationsCollectionPage,
        permissions: [
            userPermissions.PERM_CATALOG_ADMINISTER
        ]
    },
    product_rates_publications_view: {
        path: "/catalog/product-rates-publications/:entityId",
        element: ProductRatesPublicationPage,
        permissions: [
            userPermissions.PERM_CATALOG_ADMINISTER
        ]
    },
}

export default catalogRoutes
