import { Box } from "@mui/material"
import Title from "./Title";

export const DataContainer = ({ title, titleVariant, titleLevel, children }) => {
  return (
    <Box mb={1}>
      {title && (<Box sx={{
        "& > div": {
          fontWeight: 500
        }
      }}>
        <Title level={titleLevel || "h5"}>{title + (titleVariant === "label" ? ":" : "")}</Title>
      </Box >
      )}
      <Box mt={title ? 1 : 0}>
        {children}
      </Box>
    </Box >
  );
};

export default DataContainer;
