import { getActions as getRowActions } from "../ExperiencePage/ExperiencePage.actions"
import availableRoutes from "../../../../routes/availableRoutes"
import userPermissions from "../../../../lib/user-permissions"

export const getActions = (t, navigate, user) => [
    {
        id: "experience_add",
        title: t("common.create"),
        onExecute: (intermediary, progressHandler, onSuccess, onError) => {
            const baseUrl = user.settings.disfrutare.base_url
            const loginUrl = user.settings.disfrutare.login_url
            const url = (baseUrl + loginUrl).replace("[TOKEN]", localStorage.getItem("jwt"))

            window.open(url.replace("[URL]", availableRoutes.disfrutare_experience_add.path))
        },
        condition: () => user.hasPermission(userPermissions.PERM_CATALOG_ADMINISTER)

    },
]
export const getTableActions = (...props) => getRowActions(...props)
