import { useTranslation } from 'react-i18next';
import constants from '../../../../../components/entity/constants';
import { createField, createLinkField } from '../../../../../components/helpers/fieldHelper';
import ArrayDataTable from '../../../../../components/Datatable/ArrayDataTable';

const SiteFeaturedProducts = (props) => {
    const { t } = useTranslation("vbms")

    const columns = [
        createLinkField(
            createField(
                "name",
                t("catalog.products.fields.name"),
                constants.TEXT_TYPE
            ),
            {
                route: "catalog_products",
                field: "id",
            }
        ),
        createField(
            "active",
            t("catalog.products.fields.active"),
            constants.BOOLEAN_TYPE
        ),
    ]

    return (
        <ArrayDataTable
            data={props.data}
            columns={columns}
        />
    )
}

export default SiteFeaturedProducts