import { useState } from "react"
import { useDialog } from "../../../components/Dialog/dialog.context"
import { useTranslation } from "react-i18next"
import { Box, Button, Stack, DialogContentText, TextField } from "@mui/material"
import NumericField from "../../../components/form/NumericField"
import _get from "lodash/get"
import _isObject from "lodash/isObject"
import { formatCurrencyCode, formatPrice } from '../../../lib/utils';


/** Helper functions */
const hasRefundCost = (data) => data.refund_cost > 0

const hasRefundCostError = (data) => data.refund_cost === undefined || data.refund_cost === "" || data.refund_cost < 0

const hasErrorMessage = (data) => !hasRefundCost(data) && data.message === ""

const getFormattedRefundCost = (data, i18n, currency, mainCurrency, exchangeRate) => {
    if (data.refund_cost_obj && data.refund_cost !== 0) {
        if (data.refund_cost_obj.formatted_currency !== data.refund_cost_obj.main_currency.formatted_currency) {
            return data.refund_cost_obj.formatted_currency + ' (' + data.refund_cost_obj.main_currency.formatted_currency + ')';
        }

        return data.refund_cost_obj.formatted_currency
    } else {
        return formatPrice(i18n, currency, data.refund_cost, mainCurrency, exchangeRate)
    }
}

const FormButtons = ({ hideConfirmButton, hideRejectButton, onConfirm, onReject, onClose }) => {
    const { t } = useTranslation("vbms")

    let buttonIndex = 0

    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            pt={1}
        >
            <Stack direction="row" spacing={1}>
                {!hideConfirmButton &&
                    <Button
                        color="primary"
                        variant={buttonIndex++ > 0 ? "outlined" : "contained"}
                        size="small"
                        type="submit"
                        onClick={() => onConfirm()}
                    >
                        {hideRejectButton ? t("common.accept") : t("common.yes")}
                    </Button>
                }
                {!hideRejectButton &&
                    <Button
                        color="primary"
                        variant={buttonIndex++ > 0 ? "outlined" : "contained"}
                        size="small"
                        type="submit"
                        onClick={() => onReject()}
                    >
                        {t("common.no")}
                    </Button>
                }
                <Button
                    color="primary"
                    variant={buttonIndex++ > 0 ? "outlined" : "contained"}
                    size="small"
                    type="submit"
                    onClick={() => onClose()}
                >
                    {t("common.cancel")}
                </Button >
            </Stack>
        </Box>
    )
}

const BookingActionRequestWithRefundCost = ({ selection, allowEditCost, messages, currency, mainCurrency, exchangeRate, onAction, onCancel }) => {
    const { handleClose } = useDialog()
    const { i18n, t } = useTranslation("vbms")
    const existRefundCost = _get(selection, "refund_cost", null) !== null

    const [data, setData] = useState({
        refund_cost: selection.refund_cost?.value || selection.refund_cost,
        refund_cost_obj: _isObject(selection.refund_cost) ? selection.refund_cost : null,
        message: ""
    })

    const handleOnCostChange = (value, parsedValue, isValid) => {
        if (isValid && parsedValue !== data.refund_cost) {
            setData({
                ...data,
                refund_cost: value
            })
        }
    }

    const onReject = () => {
        setData({
            ...data,
            refund_cost: 0
        })
    }

    const onConfirm = () => {
        if (!allowEditCost) {
            handleClose()

            onAction(selection)
            return
        }

        if (hasErrorMessage(data)) {
            return
        }

        handleClose()

        if (hasRefundCost(data)) {
            onAction({
                ...selection,
                refund_cost: parseFloat(data.refund_cost)
            })
            return
        }

        onAction({
            ...selection,
            refund_cost: parseFloat(data.refund_cost),
            comment: data.message
        })
    }

    return (
        <>
            {exchangeRate && <Stack spacing={2}>
                {!allowEditCost &&
                    <DialogContentText>
                        {_get(messages, "conditions_text")}
                    </DialogContentText>
                }
                {allowEditCost && !existRefundCost && <>
                    <NumericField
                        id="refund_cost"
                        name="refund_cost"
                        label={t("bookings.actions.refund_cost_common.cost.title", { currency: currency !== mainCurrency ? '(' + formatCurrencyCode(i18n, currency) + ')' : '' })}
                        value={data.refund_cost}
                        type="currency"
                        variant="outlined"
                        size="small"
                        fullWidth
                        helperText={t("bookings.actions.refund_cost_common.cost.helper")}
                        InputLabelProps={{ shrink: true }}
                        sx={{ marginTop: 2 }}
                        currency={currency}
                        onChange={handleOnCostChange}
                        error={hasRefundCostError(data)}
                    />
                </>}
                {existRefundCost && hasRefundCost(data) &&
                    <DialogContentText>
                        {allowEditCost ?
                            t(_get(messages, "confirm_text", "bookings.actions.refund_cost_common.confirm_text"), { refund_cost: getFormattedRefundCost(data, i18n, currency, mainCurrency, exchangeRate) }) :
                            t(_get(messages, "confirm_text_not_allowed"))
                        }
                    </DialogContentText>
                }
                {allowEditCost && (!(hasRefundCostError(data) || hasRefundCost(data))) &&
                    <>
                        {existRefundCost &&
                            <DialogContentText>
                                {t("bookings.actions.refund_cost_common.reject_text", { refund_cost: getFormattedRefundCost(data, i18n, currency, mainCurrency, exchangeRate) })}
                            </DialogContentText>
                        }
                        <TextField
                            id="message"
                            label={t("bookings.actions.refund_cost_common.message.title")}
                            value={data.message}
                            onChange={(e) => setData({ ...data, message: e.target.value })}
                            variant="outlined"
                            size="small"
                            fullWidth
                            multiline
                            required={true}
                            rows={4}
                            helperText={t("bookings.actions.refund_cost_common.message.helper")}
                            InputLabelProps={{ shrink: true }}
                            error={hasErrorMessage(data)}
                        />
                    </>
                }
                <FormButtons
                    hideConfirmButton={allowEditCost && hasErrorMessage(data)}
                    hideRejectButton={!(existRefundCost && allowEditCost && hasRefundCost(data))}
                    onConfirm={onConfirm}
                    onReject={onReject}
                    onClose={handleClose}
                />
            </Stack>}
        </>
    )
}

export default BookingActionRequestWithRefundCost