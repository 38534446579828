import constants from "../../../../components/entity/constants"
import {
    createField,
    createLinkField,
    createArrayField,
    createCommonVisibilityMapping,
    productContainerStateMapping,
    createProductStateMapping
} from '../../../../components/helpers/fieldHelper'

export const getTableColumns = (t, user) => ([
    createField(
        "id",
        "ID",
        constants.STRING_TYPE
    ),
    createLinkField(
        createField(
            "name",
            t("catalog.products.fields.name"),
            constants.TEXT_TYPE
        ),
        {
            route: "catalog_products",
            field: "id",
        }
    ),
    createField(
        "experience.name",
        t("catalog.products.fields.experience"),
        constants.STRING_TYPE
    ),
    createArrayField(
        createField(
            "shared_experiences",
            t("catalog.products.fields.shared_experiences"),
            constants.ARRAY_TYPE
        ),
        "name"
    ),
    createField(
        "state",
        t("catalog.products.fields.state"),
        constants.STRING_TYPE,
        createProductStateMapping(t)
    ),
    createField(
        "visibility",
        t("common.visibility.title"),
        constants.STRING_TYPE,
        createCommonVisibilityMapping(t)
    ),
    createField(
        "container.state",
        t("catalog.product_containers.fields.state"),
        constants.STRING_TYPE,
        productContainerStateMapping(t)
    )
])
