import { useParams } from "react-router-dom"
import { createCallbackField } from "../../../../components/helpers/fieldHelper"
import { getHeaderContent } from "./RateGroupPage.header"
import { getTabNavigationItems } from "./RateGroupPage.tabs"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import constants from "../../../../components/entity/constants"
import AsyncEntityPage from "../../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { getActions } from "./RateGroupPage.actions"
import { getShareActions } from "./RateGroupPage.shareActions"

const RateGroupPage = (props) => {
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()

    const fetcher = () => {        
        const promises = [
            // load rate group
            apiClient.catalog.rateGroup.getRateGroup(entityId),
            // load experiences            
            apiClient.catalog.rateGroup.getRateGroupExperiences(entityId),
        ]
        return Promise.all(promises).then(([rateGroup, experiences]) => {
            rateGroup.sorted_experiences = experiences
            return rateGroup
        })
    }

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("catalog.rate_groups.view.title"),
                constants.STRING_TYPE,
                (value) => {
                    return t("catalog.rate_groups.view.title", { name: value })
                }
            )}
            headerContent={getHeaderContent(t)}
            actions={getActions(volcanoAuth.user, apiClient, entityId, t)}
            shareActions={getShareActions(volcanoAuth.user, apiClient, entityId, t)}
            tabNavigationItems={getTabNavigationItems(volcanoAuth.user, t)}
        />
    )
}

export default RateGroupPage