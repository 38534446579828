import { DialogProvider } from "../../../../../../components/Dialog/dialog.context"
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"
import AsyncEntityProductRatesTable from "../../../../components/ProductsTable"

const ProductContainerReferenceProductRates = ({ selection, containerFetcher, filterParams, hideFilter }) => {
    const { apiClient } = useVolcanoApiClient()

    if (!selection) {
        return null
    }

    const fetcher = () => {
        const params = {
            ...(filterParams || {}),
            limit: 1000,
            state: "all",
            rates_publication_id: selection?.product_rates_publication?.id || null,
            container_id: selection.id,
            container_type: selection.container_type,
        }

        return apiClient.catalog.product.getProducts(params).then((collection) => collection.getItems())
    }

    const actionHandlers = {
        sort_experiences: (containerId, data) => apiClient.catalog.productRatesPublication.editContainerExperiences(
            selection.product_rates_publication.id,
            containerId,
            {experiences: data.sorted_experiences}
        ),
        edit: (containerId, productId, data) => {
            // prepare data
            // process new and updated rates
            const productContainerData = {
                ...data,
                rates: data.rates.map(rate => ({
                    ...rate,
                    id: rate.id > 0 ? rate.id : null,
                    customer_types: rate.customer_type_ids.split(",").map((id) => ({ id: id })),
                }))
            }

            return apiClient.catalog.productRatesPublication.editContainerProduct(
                selection.product_rates_publication.id,
                containerId,
                selection.container_type,
                productId,
                productContainerData
            )
        },
    }

    return (
        <DialogProvider>
            <AsyncEntityProductRatesTable entity={selection} fetcher={fetcher} containerFetcher={containerFetcher} actionHandlers={actionHandlers} hideFilter={hideFilter} hideCustomConditions={selection.container_type !== "intermediary"} />
        </DialogProvider>
    )
}

export default ProductContainerReferenceProductRates