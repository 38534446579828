import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from '../../../../context/VolcanoAuthContext'
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import constants from "../../../../components/entity/constants"
import AsyncEntityPage from "../../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { createCallbackField } from "../../../../components/helpers/fieldHelper"
import { getHeaderContent } from "./ProductPage.header"
import { getTabNavigationItems } from "./ProductPage.tabs"
import { getActions } from "./ProductPage.actions"

const ProductPage = () => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const navigate = useNavigate()
    const volcanoAuth = useVolcanoAuth()

    const fetcher = () => apiClient.catalog.product.getProduct(entityId, false, { state: "all" })

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("catalog.products.fields.name"),
                constants.STRING_TYPE,
                (value) => t("catalog.products.view.name", { name: value })
            )}
            headerContent={getHeaderContent(t)}
            actions={getActions(apiClient, t, {}, navigate, volcanoAuth.user, entityId)}
            tabNavigationItems={getTabNavigationItems(volcanoAuth.user, apiClient, t)}
        >
        </AsyncEntityPage>
    )
}

export default ProductPage