import { AddOutlined, DeleteOutline, EditOutlined } from "@mui/icons-material"
import ProductContainerEditor from "./form/ProductContainerEditor"
import ProductContainerExperiencesOrderForm from "./form/ProductContainerExperiencesOrderForm"

const UNK_VALUE_CHAR = "-"

export const processPaxRestrictionValue = (value) => {
    if (value === null || value === undefined || value === -1) {
        return UNK_VALUE_CHAR
    }

    return value
}

export const getProductRatesTableActions = (entity, actionHandlers, t, containerFetcher) => {
    return [
        {
            id: "sort_experiences",
            title: t("catalog.product_containers.actions.sort_experiences.button"),
            scope: "table",
            withDialog: {
                title: t("catalog.product_containers.actions.sort_experiences.modal_title"),
                content: ProductContainerExperiencesOrderForm
            },
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (data, progressHandler, onSuccess, onError) => {
                return actionHandlers.sort_experiences(entity.id, data)
                    .then((result) => {
                        onSuccess(
                            t("catalog.product_containers.actions.sort_experiences.confirm")
                        )

                        return result
                    })
                    .catch((error) => {
                        onError(
                            t("catalog.product_containers.actions.sort_experiences.error")
                        )
                    })
            },
            reloadFetcher: containerFetcher,
            condition: (entity) => actionHandlers?.sort_experiences && entity.hasAction("edit_experiences"),
        },
        {
            id: "add",
            title: t("common.add"),
            scope: "row",
            icon: AddOutlined,
            confirmDialog: {
                title: t("catalog.product_containers.actions.product_add.modal_title"),
                content: (selection) =>
                    t("catalog.product_containers.actions.product_add.modal_content", {
                        name: selection.name,
                        container_name: entity.name,
                    }),
            },
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (product, progressHandler, onSuccess, onError) => {
                const data = {
                    add: [{
                        id: product.id,
                        experience_id: product.experience.id,
                        commission_percentage: 0,
                        active: true,
                        visibility: "private",
                    }]
                }

                return actionHandlers.add(entity.id, data)
                    .then((result) => {
                        onSuccess(
                            t("catalog.product_containers.actions.product_add.confirm")
                        )

                        return result
                    })
                    .catch((error) => {
                        onError(
                            t("catalog.product_containers.actions.product_add.error")
                        )
                    })
            },
            condition: (entity) => actionHandlers?.add && entity.hasAction("product_container_add"),
        },
        {
            id: "edit",
            title: t("common.edit"),
            scope: "row",
            icon: EditOutlined,
            withDialog: {
                title: t("catalog.product_containers.actions.product_edit.modal_title"),
                content: ProductContainerEditor,
                maxWidth: "md",
            },
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (result, progressHandler, onSuccess, onError) => {
                return actionHandlers.edit(entity.id, result.id, result.data)
                    .then((product) => {
                        onSuccess(
                            t("catalog.product_containers.actions.product_edit.confirm", {
                                name: result.name,
                            })
                        )

                        return product
                    })
                    .catch((error) => {
                        onError(
                            t("catalog.product_containers.actions.product_edit.error", {
                                name: result.name,
                            })
                        )

                        throw error
                    })
            },
            condition: (entity) => actionHandlers?.edit && entity.hasAction("product_container_edit"),
        },
        {
            id: "delete",
            title: t("common.delete"),
            scope: "row",
            icon: DeleteOutline,
            confirmDialog: {
                title: t("catalog.product_containers.actions.product_delete.modal_title"),
                content: (selection) =>
                    t("catalog.product_containers.actions.product_delete.modal_content", {
                        name: selection.name,
                        container_name: entity.name,
                    }),
            },
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (product, progressHandler, onSuccess, onError) => {
                return actionHandlers.delete(entity.id, product.id)
                    .then(() => {
                        onSuccess(
                            t("catalog.product_containers.actions.product_delete.confirm", {
                                name: product.name,
                                container_name: entity.name,
                            })
                        )

                        return entity
                    })
                    .catch((error) => {
                        onError(
                            t("catalog.product_containers.actions.product_delete.error", {
                                name: entity.name,
                                container_name: entity.name,
                            })
                        )

                        throw error
                    })
            },
            condition: (entity) => actionHandlers?.delete && entity.hasAction("product_container_delete"),
        }
    ]
}