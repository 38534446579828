import FormikEntityForm from "../../../../components/form/FormikEntityForm"
import { createEditableListFormField, createFormFieldset } from "../../../../components/helpers/fieldHelper"


const ProductContainerExperiencesOrderForm = ({ selection, onAction, onClose }) => {
    const handleOnConfirm = (value) => {
        onAction({
            sorted_experiences: value.sorted_experiences.map((item) => ({id: parseInt(item.id)}))
        })
    }

    const formContent = [
        createFormFieldset("", [
            createEditableListFormField(
                "sorted_experiences",
                null,
                "name",
                null,
                false,
                true,
                null,
                null,
                null,
                null
            )
        ])
    ]

    return (
        <FormikEntityForm
            entity={selection}
            formContent={formContent}
            onSave={handleOnConfirm}
        />
    )
}

export default ProductContainerExperiencesOrderForm
