import es from './es.json'
import en from './en.json'
import catalogEs from '../../pages/Catalog/config/locales/es.json'
import catalogEn from '../../pages/Catalog/config/locales/en.json'

const locales = {
    es: {
        ...es,
        ...catalogEs,
    },
    en: {
        ...en,
        ...catalogEn,
    }
}
const loadLocales = (locale) => {
    return locales[locale]
}

export default loadLocales