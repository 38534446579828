import { Link} from "@mui/material"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import _get from "lodash/get"

const DisfrutareLink = ({ href, children }) => {
    const volcanoAuth = useVolcanoAuth()
    const link = _get(volcanoAuth.user, "settings.disfrutare.url", "").replace("[URL]", href)

    if (!link) {
        return children
    }

    return <Link href={link} target="_blank">{children}</Link>
}

export default DisfrutareLink
